import type { AccessRole, Trainer } from "@trainwell/types";

export type AccessLocation =
  | "analytics"
  | "campaigns"
  | "coach_list"
  | "candidate_list"
  | "client_list"
  | "access_roles"
  | "tests"
  | "docs"
  | "data_cleaner"
  | "coach_metrics"
  | "vacations"
  | "tickets"
  | "global_habits"
  | "official_trainwell_group_chats"
  | "random_client"
  | "company_page"
  | "comp_structure"
  | "trainwell_template_library"
  | "staff_management"
  | "voice_training"
  | "product_metrics"
  | "generate_trainer_switch";

export const permissions: Record<AccessRole, AccessLocation[]> = {
  // Admins have access to everything
  admin: [],
  analytics: ["analytics", "campaigns", "random_client", "product_metrics"],
  support: [
    "analytics",
    "client_list",
    "coach_list",
    "candidate_list",
    "vacations",
    "tickets",
    "official_trainwell_group_chats",
    "generate_trainer_switch",
  ],
  coach_manager: [
    "client_list",
    "coach_list",
    "candidate_list",
    "coach_metrics",
    "vacations",
    "tickets",
    "global_habits",
    "official_trainwell_group_chats",
    "comp_structure",
    "trainwell_template_library",
    "staff_management",
    "generate_trainer_switch",
  ],
  coach_director: [
    "client_list",
    "coach_list",
    "candidate_list",
    "coach_metrics",
    "vacations",
    "tickets",
    "global_habits",
    "official_trainwell_group_chats",
    "comp_structure",
    "trainwell_template_library",
    "staff_management",
    "generate_trainer_switch",
  ],
  designer: ["random_client", "client_list"],
  developer: [
    "client_list",
    "coach_list",
    "tickets",
    "tests",
    "docs",
    "voice_training",
    "data_cleaner",
    "product_metrics",
  ],
  operations_manager: ["comp_structure", "staff_management"],
};

export function trainerHasAccess(
  accessRoles: Trainer["access_roles"],
  location: AccessLocation,
) {
  if (!accessRoles) {
    return false;
  }

  if (accessRoles?.includes("admin")) {
    return true;
  } else {
    for (const role of accessRoles) {
      if (permissions[role].includes(location)) {
        return true;
      }
    }
  }

  return false;
}
