import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { updateApiOptions } from "@trainwell/features";
import { ConfirmProvider } from "material-ui-confirm";
import { SnackbarProvider } from "notistack";
import type { ReactNode } from "react";
import { CheckForDashLock } from "src/components/misc/CheckForDashLock";
import { CheckForTrainerSurvey } from "src/components/misc/CheckForTrainerSurvey";
import ErrorComponent from "src/components/miscPages/ErrorComponent";
import { TRAINWELL_API_DOMAIN, TRAINWELL_API_KEY } from "src/config/config";
import { ThemeProvider } from "src/theme/ThemeProvider";

updateApiOptions({
  prefixUrl: TRAINWELL_API_DOMAIN,
  headers: {
    "api-key": TRAINWELL_API_KEY,
  },
});
const queryClient = new QueryClient();

type Props = {
  children: ReactNode;
};

export function AppProviders({ children }: Props) {
  return (
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider>
          <Sentry.ErrorBoundary
            fallback={({ error, resetError, eventId }) => (
              <ErrorComponent
                message={(error as any)?.message}
                onResetError={resetError}
                eventId={eventId}
              />
            )}
          >
            <ConfirmProvider
              defaultOptions={{
                cancellationButtonProps: {
                  variant: "text",
                },
              }}
            >
              <CheckForDashLock>
                <CheckForTrainerSurvey>
                  <SnackbarProvider
                    autoHideDuration={5000}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                  >
                    {children}
                  </SnackbarProvider>
                </CheckForTrainerSurvey>
              </CheckForDashLock>
            </ConfirmProvider>
          </Sentry.ErrorBoundary>
        </ThemeProvider>
      </LocalizationProvider>
      <ReactQueryDevtools buttonPosition="bottom-left" />
    </QueryClientProvider>
  );
}
