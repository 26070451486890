import type { WeekPlan } from "@trainwell/types";
import { isProgramHabit } from "./habits";

export function countWorkoutsInWeekPlans(
  workoutId: string,
  habitWeekPlans: WeekPlan[],
) {
  let count = 0;

  habitWeekPlans.forEach((plan) => {
    const habitWeekQueueIndex = plan.habit_weeks.findIndex((habitWeek) =>
      isProgramHabit(habitWeek),
    );

    if (
      habitWeekQueueIndex !== -1 &&
      plan.habit_weeks[habitWeekQueueIndex].anchored_workout_days
    ) {
      count =
        count +
        plan.habit_weeks[habitWeekQueueIndex]
          .anchored_workout_days!.flat()
          .filter((id) => id === workoutId).length;
    }
  });

  return count;
}
