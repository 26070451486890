import BookmarkAddRoundedIcon from "@mui/icons-material/BookmarkAddRounded";
import EmojiEmotionsRoundedIcon from "@mui/icons-material/EmojiEmotionsRounded";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import VideoCallRoundedIcon from "@mui/icons-material/VideoCallRounded";
import VideoLibraryRoundedIcon from "@mui/icons-material/VideoLibraryRounded";
import {
  Box,
  Grid,
  IconButton,
  InputBase,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import type { KeyboardEvent } from "react";
import { useEffect, useRef, useState } from "react";
import { EmojiPicker } from "src/components/misc/EmojiPicker";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { dismissActionItemsForClient } from "src/slices/actionItemSlice";
import {
  readChat,
  selectMessageByChatId,
  selectSelectedChat,
  sendTextMessage,
  sendVideoMessage,
  setMediaUploadUi,
  setMessage,
} from "src/slices/chatSlice";
import { selectClientById } from "src/slices/clientsSlice";
import CoachMediaDialog from "./CoachMediaDialog";
import SaveForLaterPopover from "./SaveForLaterPopover";

export default function ChatMessageBar() {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const isPwa = useAppSelector((state) => state.app.pwa);
  const selectedChat = useAppSelector(selectSelectedChat);
  const chatRef = useRef(selectedChat);
  const message = useAppSelector((state) =>
    selectedChat ? selectMessageByChatId(state, selectedChat.id) : "",
  );
  const messageRef = useRef(message);
  const textfieldRef = useRef<HTMLInputElement>();
  const [emojiPickerAnchorEl, setEmojiPickerAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [saveForLaterAnchorEl, setSaveForLaterAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const isEmojiPickerOpen = Boolean(emojiPickerAnchorEl);
  const isChatFullscreen = useAppSelector(
    (state) => state.chat.isChatFullscreen,
  );
  const socketConnected = useAppSelector((state) => state.app.socketConnected);
  const [sending, setSending] = useState(false);
  const [coachMediaDialogOpen, setCoachMediaDialogOpen] = useState(false);
  const client = useAppSelector((state) =>
    selectClientById(state, selectedChat?.id ?? ""),
  );
  const dashMode = useAppSelector((state) => state.app.dashMode);

  function closeEmojiPicker() {
    setEmojiPickerAnchorEl(null);

    setTimeout(() => {
      textfieldRef.current?.focus();
    }, 100);
  }

  const addEmoji = (emoji: string) => {
    const position = textfieldRef.current?.selectionStart;

    let newMessage = message;

    if (position !== null && position !== undefined) {
      newMessage =
        newMessage.substring(0, position) +
        emoji +
        newMessage.substring(position);
    } else {
      newMessage = newMessage + emoji;
    }

    dispatch(setMessage({ message: newMessage, chatId: chatRef.current!.id }));
    closeEmojiPicker();
  };

  useEffect(() => {
    messageRef.current = message;
  }, [message]);

  useEffect(() => {
    chatRef.current = selectedChat;
  }, [selectedChat]);

  // useEffect(() => {
  //   if (isPwa) {
  //     return;
  //   }

  //   textfieldRef.current?.focus();
  // }, [sending]);

  function sendMessage() {
    setSending(true);

    dispatch(
      sendTextMessage({
        userId: chatRef.current!.id,
        text: messageRef.current,
        toGroup: chatRef.current!.isGroupChat,
        asTrainwell: chatRef.current!.isTrainwell ?? false,
      }),
    )
      .unwrap()
      .then(() => {
        dispatch(setMessage({ chatId: chatRef.current!.id, message: "" }));
        setSending(false);

        setTimeout(() => {
          textfieldRef.current?.focus();
        }, 100);
      })
      .catch(() => {
        enqueueSnackbar("Message failed to send", {
          variant: "error",
        });
        setSending(false);
      });
  }

  function onKeyDown(
    event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    const key = event.key;

    if (
      key === "Enter" &&
      !(event.shiftKey || event.ctrlKey || event.altKey || event.metaKey) &&
      !isChatFullscreen
    ) {
      event.preventDefault();

      if (messageRef.current.length > 0) {
        sendMessage();
      }
    }
  }

  const chatDisabled =
    !socketConnected ||
    Boolean(selectedChat?.forceTrainerId) ||
    sending ||
    selectedChat?.forceDisabled;

  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.background.paper,
        borderTop: 1,
        borderColor: "divider",
      }}
    >
      {!socketConnected && (
        <Typography
          variant="body2"
          sx={{
            color: (theme) => theme.palette.text.secondary,
            textAlign: "center",
          }}
        >
          Chat server connection lost. Trying to reconnect
        </Typography>
      )}

      <Grid
        container
        sx={{
          alignItems: "center",
          p: !isChatFullscreen ? 1 : 0.5,
          pb: isChatFullscreen ? 6 : undefined,
        }}
        spacing={1}
      >
        {!isChatFullscreen ? (
          <>
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton
                onClick={() => {
                  dispatch(setMediaUploadUi("show"));
                }}
                size="small"
                disabled={chatDisabled}
              >
                <VideoCallRoundedIcon fontSize="small" />
              </IconButton>
              <IconButton
                size="small"
                disabled={chatDisabled}
                onClick={() => {
                  setCoachMediaDialogOpen(true);
                }}
                sx={{ ml: 1 }}
              >
                <VideoLibraryRoundedIcon fontSize="small" />
              </IconButton>
              <IconButton
                onClick={(event) => {
                  setEmojiPickerAnchorEl(event.currentTarget);
                }}
                size="small"
                disabled={chatDisabled}
                sx={{ ml: 1 }}
              >
                <EmojiEmotionsRoundedIcon fontSize="small" />
              </IconButton>
            </Grid>
            {client && (
              <Grid item xs display="flex" justifyContent={"flex-end"}>
                <Tooltip title="Save for later" disableInteractive>
                  <IconButton
                    size="small"
                    onClick={(event) => {
                      setSaveForLaterAnchorEl(event.currentTarget);
                    }}
                  >
                    <BookmarkAddRoundedIcon
                      fontSize="small"
                      sx={{
                        color: "#3b82f6",
                      }}
                    />
                  </IconButton>
                </Tooltip>
                <SaveForLaterPopover
                  anchorEl={saveForLaterAnchorEl}
                  onClose={() => {
                    setSaveForLaterAnchorEl(null);
                  }}
                  userId={client.user_id}
                />
              </Grid>
            )}
          </>
        ) : (
          <>
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton
                onClick={() => {
                  dispatch(setMediaUploadUi("show"));
                }}
                size="small"
                disabled={chatDisabled}
              >
                <VideoCallRoundedIcon fontSize="small" />
              </IconButton>
              <IconButton
                size="small"
                disabled={chatDisabled}
                onClick={() => {
                  setCoachMediaDialogOpen(true);
                }}
              >
                <VideoLibraryRoundedIcon fontSize="small" />
              </IconButton>
            </Grid>
            {!isChatFullscreen && (
              <Grid item>
                <IconButton
                  onClick={(event) => {
                    setEmojiPickerAnchorEl(event.currentTarget);
                  }}
                  size="small"
                  disabled={chatDisabled}
                >
                  <EmojiEmotionsRoundedIcon fontSize="small" />
                </IconButton>
              </Grid>
            )}
          </>
        )}
        <Grid
          item
          xs={!isChatFullscreen ? 12 : true}
          order={!isChatFullscreen ? 4 : undefined}
        >
          <Box
            sx={{
              overflow: "auto",
              backgroundColor: (theme) => theme.palette.background.default,
              borderRadius: 1,
              maxHeight: !isChatFullscreen ? "500px" : "300px",
              border: 1,
              borderColor: "divider",
              mb: 1,
            }}
          >
            <InputBase
              inputRef={textfieldRef}
              value={message}
              // autoFocus={isPwa ? false : true}
              sx={{ p: 0.5 }}
              fullWidth
              placeholder={"Message"}
              multiline={true}
              minRows={2}
              onClick={() => {
                if (dashMode === "programming" && !selectedChat?.isGroupChat) {
                  dispatch(readChat(selectedChat?.id ?? ""));

                  dispatch(
                    dismissActionItemsForClient({
                      userId: selectedChat?.id ?? "",
                    }),
                  );
                }
              }}
              onChange={(e) => {
                if (selectedChat) {
                  dispatch(
                    setMessage({
                      message: e.target.value,
                      chatId: selectedChat.id,
                    }),
                  );
                }
              }}
              onKeyDown={(event) => {
                onKeyDown(event);
              }}
              disabled={chatDisabled}
              name="chat_input"
            />
          </Box>
        </Grid>
        {isChatFullscreen && (
          <Grid item>
            <IconButton
              onClick={() => {
                sendMessage();
              }}
              size="small"
              disabled={chatDisabled}
            >
              <SendRoundedIcon fontSize="small" />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <Popover
        open={isEmojiPickerOpen}
        anchorEl={emojiPickerAnchorEl}
        onClose={closeEmojiPicker}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        sx={{ zIndex: 1260 }}
      >
        <EmojiPicker onSelect={addEmoji} />
      </Popover>
      <CoachMediaDialog
        open={coachMediaDialogOpen}
        onClose={(coachMedia) => {
          if (coachMedia) {
            dispatch(
              sendVideoMessage({
                video_url: coachMedia.video_url,
                thumbnail_url: coachMedia.thumbnail_url,
                userId: chatRef.current!.id,
                toGroup: chatRef.current!.isGroupChat,
                asTrainwell: chatRef.current!.isTrainwell ?? false,
                width: coachMedia.width,
                height: coachMedia.height,
              }),
            )
              .unwrap()
              .catch(() => {
                enqueueSnackbar("Video failed to send", {
                  variant: "error",
                });
              });
          }

          setCoachMediaDialogOpen(false);
        }}
      />
    </Box>
  );
}
