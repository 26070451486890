import CloudDoneOutlinedIcon from "@mui/icons-material/CloudDoneOutlined";
import CloudOffRoundedIcon from "@mui/icons-material/CloudOffRounded";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import LoopRoundedIcon from "@mui/icons-material/LoopRounded";
import { Box, Button, Tooltip, Typography, debounce } from "@mui/material";
import * as Sentry from "@sentry/react";
import type { WeekPlan } from "@trainwell/types";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { saveWeekPlans, selectAllHabitPlans } from "src/slices/clientSlice";
import { selectIsGhosting } from "src/slices/trainerSlice";

export function CalendarAutoSave() {
  const dispatch = useAppDispatch();
  const client = useAppSelector((state) => state.client.client);
  const weekPlans = useAppSelector(selectAllHabitPlans);
  const isGhosting = useAppSelector(selectIsGhosting);
  const [isAutosaveDisabled, setIsAutosaveDisabled] = useState(isGhosting);
  const [saveStatus, setSaveStatus] = useState<"saved" | "saving" | "error">(
    "saved",
  );
  const { enqueueSnackbar } = useSnackbar();

  const handleSaveWeekPlans = useCallback(
    (weekPlansToSave: WeekPlan[]) => {
      console.log(
        `Autosave: saving calendar '${client?.user_id}' and weekPlan '${weekPlansToSave[0]?.user_id}'`,
      );

      dispatch(saveWeekPlans({ weekPlans: weekPlansToSave, client: client! }))
        .unwrap()
        .then(() => {
          console.log("Autosave: saved calendar");

          setSaveStatus("saved");
        })
        .catch(() => {
          console.error("Autosave: error saving calendar");

          enqueueSnackbar("Error saving calendar", {
            variant: "error",
          });

          Sentry.captureException(new Error("Error saving calendar"));

          setSaveStatus("error");
        });
    },
    [dispatch, enqueueSnackbar],
  );

  const debouncedSaveWeekPlans = useMemo(() => {
    return debounce(handleSaveWeekPlans, 3000);
  }, [handleSaveWeekPlans]);

  useEffect(() => {
    if (isGhosting) {
      setIsAutosaveDisabled(true);
    } else {
      setIsAutosaveDisabled(false);
    }
  }, [isGhosting]);

  const didMount = useRef(false);
  useEffect(() => {
    setSaveStatus("saved");
    didMount.current = false;
    debouncedSaveWeekPlans.clear();
  }, [client?.user_id, debouncedSaveWeekPlans]);

  useEffect(() => {
    if (!client || !weekPlans?.length) {
      return;
    }

    if (!didMount.current) {
      console.log("Autosave: skip first save");

      didMount.current = true;

      return;
    }

    if (isAutosaveDisabled) {
      console.log("Autosave: disabled");

      return;
    }

    attemptSave();
  }, [dispatch, weekPlans, debouncedSaveWeekPlans]);

  function attemptSave() {
    setSaveStatus("saving");

    const weekPlansCopy = JSON.parse(JSON.stringify(weekPlans)) as WeekPlan[];

    debouncedSaveWeekPlans(weekPlansCopy);
  }

  if (!client) {
    return null;
  } else if (isAutosaveDisabled) {
    return (
      <Tooltip title={"Autosave is disabled while ghosting"}>
        <Button
          startIcon={
            <CloudOffRoundedIcon sx={{ fontSize: "10px !important" }} />
          }
          variant="text"
          color="error"
          size="small"
          onClick={() => {
            setIsAutosaveDisabled(false);

            const weekPlansCopy = JSON.parse(
              JSON.stringify(weekPlans),
            ) as WeekPlan[];

            handleSaveWeekPlans(weekPlansCopy);
          }}
          sx={{
            fontSize: 10,
            py: 0.25,
          }}
        >
          Turn autosave on
        </Button>
      </Tooltip>
    );
  } else if (saveStatus === "saved") {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <CloudDoneOutlinedIcon
          sx={{
            fontSize: (theme) => theme.typography.overline.fontSize,
            color: (theme) => theme.palette.text.secondary,
          }}
        />
        <Typography variant="overline" sx={{ ml: 0.5 }}>
          Saved
        </Typography>
      </Box>
    );
  } else if (saveStatus === "error") {
    return (
      <Tooltip title={"Click to try again"}>
        <Button
          startIcon={<ErrorRoundedIcon sx={{ fontSize: "10px !important" }} />}
          variant="text"
          color="error"
          size="small"
          onClick={() => {
            attemptSave();
          }}
          sx={{
            fontSize: 10,
            py: 0.25,
          }}
        >
          Error saving
        </Button>
      </Tooltip>
    );
  } else if (saveStatus === "saving") {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          color: (theme) => theme.palette.text.secondary,
        }}
      >
        <LoopRoundedIcon
          sx={{
            fontSize: (theme) => theme.typography.overline.fontSize,
            color: (theme) => theme.palette.text.secondary,
          }}
        />
        <Typography variant="overline" sx={{ ml: 0.5 }}>
          Saving...
        </Typography>
      </Box>
    );
  }

  return null;
}
