import VerticalAlignTopRoundedIcon from "@mui/icons-material/VerticalAlignTopRounded";
import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import type { ActionItemWorkoutReview } from "@trainwell/types";
import { format, formatDistanceToNow } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import TimezoneTypography from "src/components/misc/TimezoneTypography";
import { useAppSelector } from "src/hooks/stateHooks";
import { selectActionItemsForClient } from "src/slices/actionItemSlice";
import { selectSelectedChat } from "src/slices/chatSlice";

type Props = {
  onGoToTop: () => void;
};

export function ClientChatFooter({ onGoToTop }: Props) {
  const selectedChat = useAppSelector(selectSelectedChat);
  const workoutActionItemCount = useAppSelector(
    (state) =>
      selectActionItemsForClient(state, selectedChat?.id ?? "").filter(
        (ai) =>
          ai.type === "workout_review" &&
          (ai as ActionItemWorkoutReview).is_internal,
      ).length,
  );
  const lastLoginDate = useAppSelector(
    (state) => state.client.client?.last_login_date,
  );

  return (
    <Box>
      {workoutActionItemCount > 0 && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              borderRadius: "50px",
              backgroundColor: (theme) => theme.palette.primary.main,
              px: 1,
              py: 0.5,
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: (theme) => theme.palette.primary.contrastText,
                textAlign: "center",
              }}
            >
              {workoutActionItemCount} unread workout
              {workoutActionItemCount > 1 ? "s" : ""}
            </Typography>
          </Box>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "space-between",
          pb: 1,
        }}
      >
        <LoadingButton
          variant="text"
          size="small"
          startIcon={<VerticalAlignTopRoundedIcon />}
          onClick={onGoToTop}
          loading={selectedChat?.loadingState === "loading"}
        >
          Top
        </LoadingButton>
        <Box>
          {selectedChat?.clientTimezone !== undefined && (
            <Typography
              variant="body2"
              sx={{
                color: "text.secondary",
                textAlign: "right",
              }}
            >
              Client local time:{" "}
              <TimezoneTypography
                date={new Date()}
                otherTimezone={selectedChat.clientTimezone}
                otherTimezoneLabel="Client"
                text={format(
                  toZonedTime(
                    new Date(),
                    selectedChat?.clientTimezone ?? "America/New_York",
                  ),
                  "hh:mm aaa",
                )}
              />
            </Typography>
          )}
          {!selectedChat?.isGroupChat && (
            <Typography variant="overline">
              Last login:{" "}
              {lastLoginDate
                ? `${formatDistanceToNow(lastLoginDate)} ago`
                : "never"}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}
