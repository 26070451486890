import type { PaletteOptions } from "@mui/material/styles";
import { alpha, createTheme, darken, lighten } from "@mui/material/styles";

export type ThemeMode = "light" | "light_soft" | "dark" | "auto";

const { palette } = createTheme();

// To get pastel colors, use this site
// https://colordesigner.io/color-mixer
// Combine 3 parts background.paper and 1 part target color

export const lightTheme: PaletteOptions = {
  mode: "light",
  background: {
    paper: "#fafbfd",
    default: "#ffffff",
  },
  primary: {
    main: "#443564",
  },
  secondary: {
    main: "#0B6BCB",
  },
  success: {
    main: "#1F7A1F",
  },
  warning: {
    main: "#9A5B13",
  },
  error: {
    main: "#C41C1C",
  },
  text: {
    primary: "rgb(50, 56, 62)",
    secondary: "rgb(85, 94, 104)",
    disabled: "rgb(159, 166, 173)",
  },
  divider: "rgba(99, 107, 116, 0.2)",
  link: {
    main: "#775FAB",
  },
  successSurface: palette.augmentColor({
    color: {
      main: "#C7F7C7",
    },
  }),
  warningSurface: palette.augmentColor({
    color: {
      main: "#FCE1C2",
    },
  }),
  errorSurface: palette.augmentColor({
    color: {
      main: "#F7C5C5",
    },
  }),
  purple: palette.augmentColor({
    color: {
      main: "#443564",
    },
  }),
  purpleSurface: palette.augmentColor({
    color: {
      main: "#ddd7ea",
    },
  }),
  blue: palette.augmentColor({
    color: {
      main: "#0B6BCB",
    },
  }),
  blueSurface: palette.augmentColor({
    color: {
      main: "#C7DFF7",
    },
  }),
  pink: palette.augmentColor({
    color: {
      main: "#ea76cb",
    },
  }),
  backgroundSecondary: {
    main: "#eef2f9",
  },
  backgroundTertiary: {
    main: "#f7f7f7",
  },
  backgroundDark: {
    main: "#edf6fd",
    contrastText: "#252525",
  },
};

export const lightSoftTheme: PaletteOptions = {
  mode: "light",
  background: {
    default: "#eff1f5",
    paper: "#e6e9ef",
  },
  primary: {
    main: darken("#8839ef", 0.35),
  },
  secondary: {
    main: "#0B6BCB",
  },
  success: {
    main: "#40a02b",
  },
  warning: {
    main: "#df8e1d",
  },
  error: {
    main: "#d20f39",
  },
  text: {
    primary: "#4c4f69",
    secondary: "#5c5f77",
    disabled: "#6c6f85",
  },
  divider: alpha("#4c4f69", 0.18),
  link: {
    main: "#775FAB",
  },
  successSurface: palette.augmentColor({
    color: {
      main: lighten("#40a02b", 0.8),
    },
  }),
  warningSurface: palette.augmentColor({
    color: {
      main: lighten("#df8e1d", 0.8),
    },
  }),
  errorSurface: palette.augmentColor({
    color: {
      main: lighten("#d20f39", 0.8),
    },
  }),
  purple: palette.augmentColor({
    color: {
      main: "#8839ef",
    },
  }),
  purpleSurface: palette.augmentColor({
    color: {
      main: lighten("#8839ef", 0.8),
    },
  }),
  blue: palette.augmentColor({
    color: {
      main: "#1e66f5",
    },
  }),
  blueSurface: palette.augmentColor({
    color: {
      main: lighten("#1e66f5", 0.8),
    },
  }),
  pink: palette.augmentColor({
    color: {
      main: "#ea76cb",
    },
  }),
  backgroundSecondary: {
    main: "#dce0e8",
  },
  backgroundTertiary: {
    main: "#f7f7f7",
  },
  backgroundDark: {
    main: "#edf6fd",
    contrastText: "#252525",
  },
};

export const darkTheme: PaletteOptions = {
  ...lightTheme,
  mode: "dark",
  background: {
    default: "#1e1e2e",
    paper: "#181825",
  },
  primary: {
    main: "#cba6f7",
  },
  secondary: {
    main: "#d9bf92",
  },
  success: {
    main: "#a6e3a1",
  },
  warning: {
    main: "#f9e2af",
  },
  error: {
    main: "#f38ba8",
  },
  text: {
    primary: "#cdd6f4",
    secondary: "#bac2de",
    disabled: "#a6adc8",
  },
  divider: alpha("#cdd6f4", 0.18),
  link: {
    main: "#775FAB",
  },
  successSurface: palette.augmentColor({
    color: {
      main: darken("#a6e3a1", 0.75),
    },
  }),
  warningSurface: palette.augmentColor({
    color: {
      main: darken("#f9e2af", 0.75),
    },
  }),
  errorSurface: palette.augmentColor({
    color: {
      main: darken("#f38ba8", 0.75),
    },
  }),
  purple: palette.augmentColor({
    color: {
      main: "#cba6f7",
    },
  }),
  purpleSurface: palette.augmentColor({
    color: {
      main: darken("#cba6f7", 0.75),
    },
  }),
  blue: palette.augmentColor({
    color: {
      main: "#89b4fa",
    },
  }),
  blueSurface: palette.augmentColor({
    color: {
      main: darken("#89b4fa", 0.75),
    },
  }),
  pink: palette.augmentColor({
    color: {
      main: "#f5c2e7",
    },
  }),
  backgroundSecondary: {
    main: "#11111b",
  },
  backgroundTertiary: {
    main: "#181818",
  },
  backgroundDark: {
    main: "#1f1f1f",
    contrastText: "#cccccc",
  },
};
