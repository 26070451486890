import { type Message } from "@trainwell/types";

type Props = {
  message: Message;
  combineBottom: boolean;
};

export function MealLogReportMessage({ message, combineBottom }: Props) {
  return null;
}
