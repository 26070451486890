import OpenInFullRoundedIcon from "@mui/icons-material/OpenInFullRounded";
import { Box, IconButton, Popover, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { getClientStatusColor, getClientSummary } from "src/lib/client";
import {
  toggleEquipmentDialogOpen,
  toggleNotesDialogOpen,
} from "src/slices/clientSlice";
import { ClientNotes } from "../ClientNotes";
import ClientProfilePicture from "../ClientProfilePicture";
import ClientTags from "../ClientTags";
import ClientCard from "../name-tag/ClientCard";
import { ClientChips } from "../name-tag/ClientChips";
import AuditPanel from "./AuditPanel";
import { ClientEquipment } from "./ClientEquipment";
import ExerciseProgressPanel from "./ExerciseProgressPanel";
import LevelPanel from "./LevelPanel";
import MorePanel from "./MorePanel";
import OnboardingVideosPanel from "./OnboardingVideosPanel";
import ProgressPanel from "./ProgressPanel";
import ProgressPicturesPanel from "./ProgressPicturesPanel";
import ReferralsPanel from "./ReferralsPanel";
import SettingsPanel from "./SettingsPanel";
import SurveysPanel from "./SurveysPanel";
import SwapPanel from "./SwapPanel";
import TagsPanel from "./TagsPanel";
import TicketsPanel from "./TicketsPanel";

export function ClientColumn() {
  const dispatch = useAppDispatch();
  const client = useAppSelector((state) => state.client.client);
  const savingNotes = useAppSelector((state) => state.client.notesSaving);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { logId, workoutId } = useParams();
  const isWorkoutOrLogOpen = Boolean(workoutId || logId);

  const userId = client?.user_id ?? "";

  if (!client) {
    return null;
  }

  const clientSummary = getClientSummary(client.account);

  return (
    <Box sx={{ py: 1, pb: 16 }}>
      <Box sx={{ px: 1, mb: 2 }}>
        {isWorkoutOrLogOpen && (
          <>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box
                onMouseEnter={(event) => {
                  setAnchorEl(event.currentTarget);
                }}
                onMouseLeave={() => {
                  setAnchorEl(null);
                }}
              >
                <ClientProfilePicture dimension={88} userId={client.user_id} />
              </Box>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography variant="h1">👋 {client.full_name}</Typography>
              {client.goal ? (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography>{client.goal.name_custom}</Typography>
                </Box>
              ) : (
                <Typography
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                >
                  No goal yet
                </Typography>
              )}
              <Typography
                variant="body2"
                sx={{
                  color: (theme) => getClientStatusColor(client, theme),
                }}
              >
                {clientSummary.text}
              </Typography>
              <ClientChips sx={{ mb: 1, mt: 1 }} />
              <ClientTags userId={client.user_id} />
            </Box>
          </>
        )}
        <Box
          sx={{
            mb: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h2">Equipment</Typography>
          <Tooltip placement="right" disableInteractive title="Fullscreen 'E'">
            <IconButton
              size="small"
              onClick={() => {
                dispatch(toggleEquipmentDialogOpen());
              }}
            >
              <OpenInFullRoundedIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
        <ClientEquipment
          userId={client.user_id}
          defaultCollapsed
          sx={{ mb: 2 }}
        />
        <Box
          sx={{
            mb: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h2">Notes</Typography>
          <Tooltip placement="right" disableInteractive title="Fullscreen 'N'">
            <IconButton
              size="small"
              onClick={() => {
                dispatch(toggleNotesDialogOpen());
              }}
              disabled={savingNotes}
            >
              <OpenInFullRoundedIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
        <ClientNotes userId={client.user_id} />
      </Box>

      <ProgressPanel userId={userId} condensed={!open} />

      <ReferralsPanel userId={userId} condensed={!open} />

      <ExerciseProgressPanel userId={userId} condensed={!open} />

      <ProgressPicturesPanel userId={userId} />

      <SettingsPanel userId={userId} />

      <SurveysPanel userId={userId} />

      <OnboardingVideosPanel userId={userId} />

      <TagsPanel userId={userId} />

      <LevelPanel userId={userId} />

      <AuditPanel userId={userId} />

      <TicketsPanel userId={userId} />

      <SwapPanel userId={userId} />

      <MorePanel userId={userId} />
      <Popover
        sx={{
          pointerEvents: "none",
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={() => {
          setAnchorEl(null);
        }}
        disableRestoreFocus
        slotProps={{
          paper: {
            sx: {
              maxWidth: 650,
            },
          },
        }}
      >
        <ClientCard />
      </Popover>
    </Box>
  );
}
