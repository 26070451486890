import { Box, Paper, ThemeProvider, useMediaQuery } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import ChatList from "src/features/chat/ChatList";
import { ChatListHeader } from "src/features/chat/ChatListHeader";
import CheckInPanel from "src/features/chat/CheckInPanel";
import { ClientChat } from "src/features/chat/client-chat/ClientChat";
import { ClientChatHeader } from "src/features/chat/ClientChatHeader";
import { ThreadsList } from "src/features/chat/threads/ThreadsList";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import {
  openChat,
  selectChatThemeMode,
  selectSelectedChat,
  toggleHideClientChat,
} from "src/slices/chatSlice";
import getTheme from "src/theme/theme";

export function Chat() {
  const dispatch = useAppDispatch();
  const isSelectingChats = useAppSelector(
    (state) => state.chat.isSelectingChats,
  );
  const isChatFullscreen = useAppSelector(
    (state) => state.chat.isChatFullscreen,
  );
  const hideClientChat = useAppSelector((state) => state.chat.hideClientChat);
  const hasSelectedChat = useAppSelector((state) =>
    Boolean(selectSelectedChat(state)),
  );
  const chatThemeMode = useAppSelector(selectChatThemeMode);
  const chatTheme = useMemo(() => getTheme(chatThemeMode), [chatThemeMode]);
  const showFullChats =
    (useMediaQuery("(min-width:1800px)") || !hasSelectedChat) &&
    !hideClientChat;
  const chatView = useAppSelector((state) => state.chat.view);
  const trainerLoaded = useAppSelector((state) =>
    Boolean(state.trainer.trainer?.trainer_id),
  );

  useHotkeys("c", () => {
    dispatch(toggleHideClientChat());
  });

  const width =
    chatView === "threads" || isSelectingChats
      ? "400px"
      : !isSelectingChats
        ? "320px"
        : "240px";

  if (isChatFullscreen || !trainerLoaded) {
    return null;
  }

  console.log("hasSelectedChat", hasSelectedChat);

  return (
    <ThemeProvider theme={chatTheme}>
      <Paper elevation={0} sx={{ display: "flex" }}>
        <Box
          sx={{
            minWidth: showFullChats ? width : "48px",
            width: showFullChats ? width : "48px",
            borderRight: 1,
            borderColor: chatTheme.palette.divider,
            mt: "38px",
            overflowY: "auto",
            height: "calc(100vh - 38px)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ChatListHeader condensed={!showFullChats} />
          <Box sx={{ flex: 1 }}>
            {chatView === "threads" ? (
              <ThreadsList />
            ) : chatView === "check_in" ? (
              <CheckInPanel condensed={!showFullChats} />
            ) : (
              <ChatList condensed={!showFullChats} />
            )}
          </Box>
        </Box>
        {(chatView === "default" || chatView === "check_in") &&
          !hideClientChat && (
            <>
              {hasSelectedChat && (
                <Box
                  sx={{
                    width: "320px",
                    minWidth: "320px",
                    borderRight: 1,
                    borderColor: "divider",
                    mt: "38px",
                    overflowY: "auto",
                    height: "calc(100vh - 38px)",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <ClientChatHeader />
                  <ClientChatWrapper />
                </Box>
              )}
            </>
          )}
        <Watcher />
      </Paper>
    </ThemeProvider>
  );
}

function Watcher() {
  const dispatch = useAppDispatch();
  const chatId = useAppSelector(
    (state) => state.chat.selectedChatId || state.client.client?.user_id,
  );
  const hasSelectedChat = useAppSelector((state) =>
    Boolean(selectSelectedChat(state)),
  );

  useEffect(() => {
    if (chatId && !hasSelectedChat) {
      dispatch(openChat({ chatId: chatId }));
    }
  }, [chatId, dispatch]);

  return null;
}

function ClientChatWrapper() {
  const cid = useAppSelector((state) => selectSelectedChat(state)?.id);
  const isAuditMode = useAppSelector((state) => state.app.isAuditMode);

  return <ClientChat key={cid + String(isAuditMode)} />;
}
