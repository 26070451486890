import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import {
  Box,
  Button,
  Popover,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import ChatListFilter from "src/features/chat/ChatListFilter";

type Props = {
  filters: string[];
  onChange: (newFilters: string[]) => void;
  mode: "and" | "or";
  onModeChange: (mode: "and" | "or") => void;
  disabled?: boolean;
};

export default function ClientFilterButton({
  filters,
  onChange,
  mode,
  onModeChange,
  disabled,
}: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 150);
    }
  }, [open]);

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Tooltip title="Filter" enterDelay={1000}>
          <Button
            disabled={disabled}
            size="small"
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
            }}
            variant="text"
            sx={{
              backgroundColor: (theme) =>
                filters.length > 0
                  ? alpha(theme.palette.primary.main, 0.075)
                  : undefined,
              minWidth: "20px",
            }}
          >
            <FilterListRoundedIcon />
            {filters.length > 0 && (
              <Button
                disabled={disabled}
                size="small"
                variant="text"
                onMouseOver={(event) => {
                  event.stopPropagation();
                }}
                onMouseEnter={(event) => {
                  event.stopPropagation();
                }}
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
                onClick={(event) => {
                  event.stopPropagation();

                  onChange([]);
                }}
                sx={{
                  py: 0.25,
                  px: 0.5,
                  minWidth: "20px",
                  "& .MuiButton-endIcon": {
                    ml: 0.5,
                  },
                  lineHeight: 1.5,
                }}
                endIcon={<ClearRoundedIcon />}
              >
                {filters.length}
              </Button>
            )}
          </Button>
        </Tooltip>
      </Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: { width: 400, p: 2 },
          },
        }}
      >
        <Typography variant="h2" sx={{ mb: 2 }}>
          Filter
        </Typography>
        <ChatListFilter
          selectedFilters={filters}
          onToggle={(newFilter) => {
            const newFilters = [...filters];

            const index = newFilters.findIndex((f) => f === newFilter);

            if (index === -1) {
              newFilters.push(newFilter);
            } else {
              newFilters.splice(index, 1);
            }

            onChange(newFilters);
          }}
        />
        <ToggleButtonGroup
          color="primary"
          value={mode}
          exclusive
          sx={{ mt: 1 }}
          size="small"
          onChange={(event, newValue) => {
            if (newValue === null) {
              return;
            }

            onModeChange(newValue);
          }}
        >
          <ToggleButton value="and" sx={{ py: 0.25 }}>
            And
          </ToggleButton>
          <ToggleButton value="or" sx={{ py: 0.25 }}>
            Or
          </ToggleButton>
        </ToggleButtonGroup>
      </Popover>
    </>
  );
}
