import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import PhotoCameraFrontRoundedIcon from "@mui/icons-material/PhotoCameraFrontRounded";
import TimelineRoundedIcon from "@mui/icons-material/TimelineRounded";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import { useProgressMetrics } from "@trainwell/features";
import type { MessageProgressLogged } from "@trainwell/types";
import { useState } from "react";
import { DialogTitleWithClose } from "src/components/misc/DialogTitleWithClose";
import GoalCardMetric from "src/features/goals/GoalCardMetric";
import { useAppSelector } from "src/hooks/stateHooks";

function round(number: number) {
  return Math.round(number * 10) / 10;
}

type Props = {
  message: MessageProgressLogged;
  combineBottom: boolean;
};

export function ProgressLoggedMessage({ message, combineBottom }: Props) {
  const client = useAppSelector((state) => state.client.client);
  const { data } = useProgressMetrics();
  const progressMetrics = data?.progress_metrics ?? [];
  const [showGraph, setShowGraph] = useState(false);
  const [progressPictureOpen, setProgressPictureOpen] = useState(false);

  const progressMetric = progressMetrics.find(
    // These optionals are here due to a kris bug...
    (p) => p.id === message.content?.progress_metric_log?.progress_metric_id,
  );

  const value = round(
    (message.content.progress_metric_log.measurement ?? 0) *
      (client?.preferred_weight_system === "imperial"
        ? 1
        : (progressMetric?.ratio_metric ?? 1)),
  );

  const units =
    client?.preferred_weight_system === "imperial"
      ? progressMetric?.unit_imperial
      : progressMetric?.unit_metric;

  if (progressMetric?.id === "progress_picture") {
    return (
      <Box
        sx={{
          maxWidth: "300px",
          borderRadius: `12px 12px 12px ${!combineBottom ? "0px" : "12px"}`,
          overflow: "hidden",
          backgroundColor: (theme) => theme.palette.background.default,
          p: 1,
          border: 1,
          borderColor: "divider",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <PhotoCameraFrontRoundedIcon
            sx={{
              fontSize: 16,
              color: (theme) => theme.palette.text.primary,
              mr: 1,
            }}
          />
          <Typography
            variant="h6"
            sx={{
              color: (theme) => theme.palette.text.primary,
            }}
          >
            Progress picture
          </Typography>
          <IconButton
            onClick={() => {
              setShowGraph(!showGraph);
            }}
            sx={{ ml: 1 }}
          >
            {showGraph ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
          </IconButton>
        </Box>
        {showGraph && (
          <Box sx={{ pt: 1 }}>
            <img
              src={message.content?.progress_metric_log?.url_image ?? ""}
              height={150}
              style={{
                borderRadius: "12px",
              }}
              onClick={() => {
                setProgressPictureOpen(true);
              }}
            />
          </Box>
        )}
        <Dialog
          onClose={() => {
            setProgressPictureOpen(false);
          }}
          open={progressPictureOpen}
          maxWidth="lg"
        >
          <DialogTitleWithClose
            onClose={() => {
              setProgressPictureOpen(false);
            }}
          >
            Progress picture
          </DialogTitleWithClose>
          <DialogContent dividers>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                borderRadius: 1,
                overflow: "hidden",
              }}
            >
              <img
                height={500}
                width={500}
                src={message.content?.progress_metric_log?.url_image ?? ""}
                alt="client profile picture"
                style={{
                  maxWidth: "100%",
                  display: "block",
                  objectFit: "contain",
                }}
              />
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        maxWidth: "300px",
        borderRadius: `12px 12px 12px ${!combineBottom ? "0px" : "12px"}`,
        overflow: "hidden",
        backgroundColor: (theme) => theme.palette.background.default,
        p: 1,
        border: 1,
        borderColor: "divider",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <TimelineRoundedIcon
          sx={{
            fontSize: 16,
            color: (theme) => theme.palette.text.primary,
            mr: 1,
          }}
        />
        <Box>
          <Typography
            variant="h6"
            sx={{
              color: (theme) => theme.palette.text.primary,
            }}
          >
            Progress logged
          </Typography>
          <Typography>
            {progressMetric?.name}: <b>{value}</b> {units}
          </Typography>
        </Box>
        <IconButton
          onClick={() => {
            setShowGraph(!showGraph);
          }}
          sx={{ ml: 1 }}
        >
          {showGraph ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
        </IconButton>
      </Box>
      {showGraph && (
        <Box sx={{ pt: 1 }}>
          <GoalCardMetric
            preferredWeightSystem={
              client?.preferred_weight_system ?? "imperial"
            }
            userId={client?.user_id ?? ""}
            progressMetricId={
              message.content.progress_metric_log.progress_metric_id
            }
            size="small"
          />
        </Box>
      )}
    </Box>
  );
}
