import CalendarViewWeekRoundedIcon from "@mui/icons-material/CalendarViewWeekRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import FitnessCenterRoundedIcon from "@mui/icons-material/FitnessCenterRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  alpha,
} from "@mui/material";
import { memo, useState } from "react";
import HabitDot from "src/components/misc/HabitDot";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import {
  getHabitDetails,
  getSelectedDays,
  isHabitWeekPlanCurrentWeek,
  isHabitWeekPlanInPast,
  isProgramHabit,
} from "src/lib/habits";
import { logClick } from "src/slices/analyticsSlice";
import {
  addWeekToPhase,
  clearWorkoutHabitWeek,
  removeHabitWeekFromPlan,
  selectHabitPlanById,
  turnWeekIntoPhase,
  updateHabitWeek,
} from "src/slices/clientSlice";
import { selectPhaseById, selectWorkoutById } from "src/slices/phasesSlice";
import HabitEditFields from "../habit-column/HabitEditFields";
import HabitDayCell from "./HabitDayCell";

const days = ["S", "M", "T", "W", "T", "F", "S"];

type Props = {
  habitWeekId: string;
  weekPlanId: string;
  isDragging: boolean;
};

const HabitWeek = memo(function HabitWeek({
  habitWeekId,
  weekPlanId,
  isDragging,
}: Props) {
  const dispatch = useAppDispatch();
  const [isEditMode, setIsEditMode] = useState(false);
  const weekPlanDate = useAppSelector(
    (state) => selectHabitPlanById(state, weekPlanId).date,
  );
  const habitWeek = useAppSelector(
    (state) =>
      selectHabitPlanById(state, weekPlanId)?.habit_weeks.find(
        (w) => w.id === habitWeekId,
      )!,
  );
  const isEditingPhase = useAppSelector(
    (state) => state.phases.phaseEditing !== null,
  );
  const canAddToPhase = useAppSelector((state) => {
    if (!isProgramHabit(habitWeek)) {
      return false;
    }

    const editingWorkoutsIds = (
      state.phases.phaseEditing?.days_draggable.map((d) =>
        d.workouts.map((w) => w.workout_id),
      ) ?? [[]]
    ).flat();

    const workoutIds = (habitWeek.anchored_workout_days ?? [])
      .flat()
      .filter(Boolean) as string[];

    if (
      workoutIds.some((w) => editingWorkoutsIds.includes(w)) ||
      editingWorkoutsIds.some((w) => workoutIds.includes(w))
    ) {
      return false;
    }

    if (workoutIds.length === 0) {
      return false;
    }

    for (const workoutId of workoutIds) {
      const workout = selectWorkoutById(state, workoutId);
      const phase = selectPhaseById(state, workout?.phase_id ?? "");

      if (phase?.type === "multiple") {
        return false;
      }
    }

    return true;
  });
  let selectedDays = getSelectedDays(habitWeek.schedule, habitWeek.custom_days);
  const goal = useAppSelector((state) => state.client.client?.goal);
  const measurementDay = useAppSelector(
    (state) => state.client.client?.settings?.progress_metrics?.measurement_day,
  );

  const forceTaskNames: string[] = [];
  if (habitWeek.type === "progress_metric_measurement") {
    selectedDays = [];

    Array.from([0, 1, 2, 3, 4, 5, 6]).map((dayIndex) => {
      const selected =
        goal?.progress_metric_summaries?.some(
          (p) => p.measurement_frequency === "daily",
        ) ||
        (goal?.progress_metric_summaries?.length
          ? dayIndex === measurementDay
          : false);

      selectedDays.push(selected);

      if (selected) {
        let name = `Log `;

        const progressSummaries = goal?.progress_metric_summaries?.filter(
          (p) =>
            p.measurement_frequency === "daily" ||
            (p.measurement_frequency === "weekly" &&
              dayIndex === measurementDay),
        );
        const names = progressSummaries
          ?.map((p) => p.progress_metric?.name)
          .join(", ");

        name += names;
        forceTaskNames.push(name);
      } else {
        forceTaskNames.push("");
      }
    });
  }

  const isPastWeek = isHabitWeekPlanInPast(weekPlanDate);
  const isCurrentWeek = !isPastWeek && isHabitWeekPlanCurrentWeek(weekPlanDate);

  return (
    <Box sx={{ px: 1, py: 0.5 }}>
      <Accordion
        defaultExpanded={isProgramHabit(habitWeek)}
        disableGutters
        elevation={0}
        square
        sx={{
          overflow: "hidden",
          backgroundColor: (theme) => theme.palette.background.paper,
          opacity: isDragging ? 0.4 : 1,
          border: 1,
          borderColor: "divider",
          borderRadius: 1,
        }}
        slotProps={{ transition: { unmountOnExit: true } }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreRoundedIcon />}
          sx={{
            minHeight: "30px",
            backgroundColor: (theme) =>
              isProgramHabit(habitWeek)
                ? alpha(theme.palette.blue.main, 0.05)
                : undefined,
            display: "flex",
            alignItems: isEditMode ? "flex-start" : "center",
            px: 1,
            py: 0.5,
            "& .MuiAccordionSummary-content": {
              m: 0,
              justifyContent: "space-between",
            },
          }}
        >
          {!isEditMode ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {isProgramHabit(habitWeek) ? (
                <Avatar
                  sx={{
                    backgroundColor: (theme) => theme.palette.blue.main,
                    width: 20,
                    height: 20,
                    borderRadius: "6px",
                  }}
                >
                  <FitnessCenterRoundedIcon
                    sx={{
                      fontSize: 16,
                    }}
                  />
                </Avatar>
              ) : (
                <HabitDot type={habitWeek.type} complete />
              )}
              <Box sx={{ ml: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      mr: 1,
                    }}
                  >
                    {isProgramHabit(habitWeek) ? "Workouts" : habitWeek.name}
                  </Typography>
                  {!isProgramHabit(habitWeek) &&
                    habitWeek.type !== "progress_metric_measurement" && (
                      <Typography
                        variant="body2"
                        sx={{
                          color: (theme) => theme.palette.text.secondary,
                        }}
                      >
                        {getHabitDetails(habitWeek)}
                      </Typography>
                    )}
                </Box>
                {!isProgramHabit(habitWeek) && (
                  <Typography variant="overline">
                    {habitWeek.notes_coach_default}
                  </Typography>
                )}
              </Box>
            </Box>
          ) : (
            <HabitEditFields
              habit={habitWeek}
              size="small"
              onHabitChange={(update) => {
                dispatch(
                  updateHabitWeek({
                    planId: weekPlanId,
                    habitWeek: {
                      id: habitWeek.id,
                      ...update,
                    },
                  }),
                );
              }}
              sx={{ maxWidth: "500px" }}
            />
          )}
          <Box>
            {!isProgramHabit(habitWeek) &&
              !isPastWeek &&
              habitWeek.type !== "progress_metric_measurement" && (
                <Button
                  startIcon={
                    isEditMode ? <SaveRoundedIcon /> : <EditRoundedIcon />
                  }
                  sx={{ mr: 1 }}
                  onClick={() => {
                    setIsEditMode(!isEditMode);
                  }}
                  size="small"
                  variant={isEditMode ? "contained" : "text"}
                >
                  {isEditMode ? "Save" : "Edit"}
                </Button>
              )}
            {!isEditMode &&
              !isCurrentWeek &&
              !isPastWeek &&
              habitWeek.type !== "progress_metric_measurement" &&
              !isProgramHabit(habitWeek) && (
                <IconButton
                  color="error"
                  size="small"
                  sx={{ mr: 1 }}
                  onClick={() => {
                    dispatch(
                      removeHabitWeekFromPlan({
                        habitWeekPlanId: weekPlanId,
                        habitWeekId: habitWeek.id,
                      }),
                    );
                  }}
                >
                  <RemoveCircleRoundedIcon sx={{ fontSize: 16 }} />
                </IconButton>
              )}
            {isProgramHabit(habitWeek) && (
              <Button
                startIcon={<CalendarViewWeekRoundedIcon />}
                disabled={!canAddToPhase}
                size="small"
                variant="text"
                sx={{ py: 0.25, mr: 1 }}
                onClick={(event) => {
                  event.stopPropagation();

                  if (isEditingPhase) {
                    dispatch(
                      addWeekToPhase({
                        weekPlanId: weekPlanId,
                      }),
                    );
                  } else {
                    dispatch(
                      turnWeekIntoPhase({
                        weekPlanId: weekPlanId,
                      }),
                    );
                  }

                  dispatch(
                    logClick({
                      elementName: "plan_builder.week.new_phase",
                    }),
                  );
                }}
              >
                {isEditingPhase ? "Add to phase" : "New phase"}
              </Button>
            )}
            {!isCurrentWeek && !isPastWeek && isProgramHabit(habitWeek) && (
              <Button
                startIcon={<ClearRoundedIcon />}
                size="small"
                variant="text"
                color="error"
                sx={{ py: 0.25, mr: 1 }}
                onClick={(event) => {
                  event.stopPropagation();

                  dispatch(
                    clearWorkoutHabitWeek({
                      weekPlanId: weekPlanId,
                    }),
                  );

                  dispatch(
                    logClick({
                      elementName: "plan_builder.week.clear_workouts",
                    }),
                  );
                }}
              >
                Clear
              </Button>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <Grid
            container
            columns={7}
            alignItems="stretch"
            sx={{ borderTop: 1, borderColor: "divider" }}
          >
            {days.map((day, dayIndex) => (
              <Grid
                key={dayIndex}
                item
                xs={1}
                sx={{
                  borderLeft: dayIndex === 0 ? undefined : 1,
                  borderColor: "divider",
                  backgroundColor: (theme) => theme.palette.background.paper,
                }}
              >
                <HabitDayCell
                  habitWeekId={habitWeekId}
                  dayIndex={dayIndex}
                  weekPlanId={weekPlanId}
                  isSelected={selectedDays[dayIndex]}
                  isEditMode={isEditMode}
                  forceTaskNames={forceTaskNames}
                />
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
});

export default HabitWeek;
