import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import DirectionsRunRoundedIcon from "@mui/icons-material/DirectionsRunRounded";
import {
  Avatar,
  Box,
  ButtonBase,
  CardActionArea,
  Dialog,
  DialogContent,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import type { HabitTaskMessage } from "@trainwell/types";
import { format } from "date-fns";
import { useState } from "react";
import { DialogTitleWithClose } from "src/components/misc/DialogTitleWithClose";
import { WorkoutLogPreviewPopover } from "src/features/workout-logs/WorkoutLogPreviewPopover";
import { useAppSelector } from "src/hooks/stateHooks";
import { selectActionItemsForClient } from "src/slices/actionItemSlice";
import { useGetWorkoutLogAlertsQuery } from "src/slices/api/logApi";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";

type Props = {
  message: HabitTaskMessage;
  combineBottom: boolean;
};

export function WorkoutMessage({ message, combineBottom }: Props) {
  const isChatFullscreen = useAppSelector(
    (state) => state.chat.isChatFullscreen,
  );
  const isVipTrainer = useAppSelector(
    (state) => selectPrimaryTrainer(state)?.is_vip_trainer,
  );
  const [fitframOpen, setFitframeOpen] = useState(false);
  const [previewAnchorEl, setPreviewAnchorEl] = useState<HTMLElement | null>(
    null,
  );
  const { data: logUpdates } = useGetWorkoutLogAlertsQuery(
    message.habit_task.workout_log_id ?? "",
    {
      skip:
        !message.habit_task.workout_log_id || !message.habit_task.workout_id,
    },
  );
  const isAuditMode = useAppSelector((state) => state.app.isAuditMode);
  const incompleteLogUpdates =
    logUpdates?.workout_log_alerts.filter((u) => !u.date_addressed) ?? [];
  const actionItem = useAppSelector((state) => {
    const items = selectActionItemsForClient(state, message.from_id);

    const matchingActionItem = items.find(
      (item) =>
        item.type === "workout_review" &&
        item.log_id === message.habit_task.workout_log_id,
    );

    return matchingActionItem;
  });

  if (!message.habit_task) {
    return <Typography>Error loading habit message</Typography>;
  }

  const prCount = !message.habit_task.workout_log
    ? null
    : message.habit_task.workout_log.sections
        .map((s) =>
          s.cycles.map((c) =>
            c.exercises.map((e) => e.sets.filter((s) => s.is_pr_new)),
          ),
        )
        .flat()
        .flat()
        .flat().length;

  return (
    <>
      <CardActionArea
        href={
          isChatFullscreen
            ? ""
            : message.habit_task.workout_log_id
              ? `/clients/${message.habit_task.user_id}/logs/${message.habit_task.workout_log_id}`
              : ""
        }
        onClick={(event) => {
          if (isChatFullscreen && isVipTrainer) {
            setPreviewAnchorEl(event.currentTarget);
          }
        }}
        sx={{
          maxWidth: "300px",
          borderRadius: `12px 12px 12px ${!combineBottom ? "0px" : "12px"}`,
          overflow: "hidden",
          backgroundColor: (theme) => theme.palette.background.default,
          p: 1,
          display: "flex",
          alignItems: "center",
          border: 1,
          borderColor: "divider",
        }}
      >
        <Stack
          direction={"column"}
          spacing={1}
          useFlexGap
          sx={{
            whiteSpace: "pre-line",
            wordWrap: "break-word",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {actionItem && (
              <CircleRoundedIcon
                sx={{ fontSize: 12, mr: 1 }}
                color={"inherit"}
              />
            )}
            <Avatar
              sx={{
                backgroundColor: "#a3e635",
                mr: 1,
                width: 20,
                height: 20,
                borderRadius: "6px",
              }}
            >
              <DirectionsRunRoundedIcon
                sx={{
                  fontSize: 16,
                  color: (theme) => theme.palette.text.primary,
                }}
              />
            </Avatar>
            <Box>
              <Typography
                variant="h6"
                sx={{
                  color: (theme) => theme.palette.text.primary,
                }}
              >
                {message.habit_task.name}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: (theme) => theme.palette.text.secondary,
                }}
              >
                {format(
                  new Date(message.habit_task.date_completed as string),
                  "MMM do, yyyy h:mm a",
                )}
              </Typography>
            </Box>
          </Box>
          {message.habit_task.notes_client && (
            <Typography
              sx={{
                color: (theme) => theme.palette.text.primary,
              }}
            >
              {message.habit_task.notes_client}
            </Typography>
          )}
          {message.habit_task.workout_log?.share_photo_with_coach &&
            message.habit_task.workout_log.photo_url && (
              <ButtonBase
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();

                  setFitframeOpen(true);
                }}
                onMouseDown={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                }}
                sx={{
                  borderRadius: 1,
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "start",
                }}
              >
                <img
                  height={150}
                  style={{
                    maxWidth: "120px",
                    display: "block",
                    objectFit: "cover",
                    borderRadius: "8px",
                  }}
                  src={message.habit_task.workout_log.photo_url}
                />
              </ButtonBase>
            )}
          <Typography variant="overline">
            {isAuditMode &&
            logUpdates?.workout_log_alerts.length !==
              incompleteLogUpdates.length ? (
              <>
                <s>{logUpdates?.workout_log_alerts.length}</s>{" "}
              </>
            ) : (
              ""
            )}
            {incompleteLogUpdates.length ?? "No"} workout alerts
          </Typography>
          {prCount !== null && prCount > 0 && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <SvgIcon fontSize="inherit" sx={{ color: "#9D7F10", mr: 0.5 }}>
                <path d="M12 8L15 13.2L18 10.5L17.3 14H6.7L6 10.5L9 13.2L12 8M12 4L8.5 10L3 5L5 16H19L21 5L15.5 10L12 4M19 18H5V19C5 19.6 5.4 20 6 20H18C18.6 20 19 19.6 19 19V18Z" />
              </SvgIcon>
              <Typography variant="body2" sx={{ color: "#9D7F10" }}>
                {prCount} personal record{prCount > 1 ? "s" : ""}
              </Typography>
            </Box>
          )}
        </Stack>
      </CardActionArea>
      <Dialog
        onClose={() => {
          setFitframeOpen(false);
        }}
        open={fitframOpen}
        maxWidth="lg"
      >
        <DialogTitleWithClose
          onClose={() => {
            setFitframeOpen(false);
          }}
        >
          FitFrame
        </DialogTitleWithClose>
        <DialogContent dividers>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              borderRadius: 1,
              overflow: "hidden",
            }}
          >
            <img
              height={800}
              src={
                message.habit_task.workout_log?.photo_url ||
                "/assets/profile.png"
              }
              alt="fitframe"
              style={{
                maxWidth: "100%",
                display: "block",
                objectFit: "contain",
              }}
            />
          </Box>
        </DialogContent>
      </Dialog>
      <WorkoutLogPreviewPopover
        anchorEl={previewAnchorEl}
        onClose={() => {
          setPreviewAnchorEl(null);
        }}
        workoutLogId={message.habit_task.workout_log_id ?? ""}
      />
    </>
  );
}
