import type { Ticket } from "@trainwell/types";
import { normalize, schema } from "normalizr";

export type NormalizedTickets = {
  ids: string[];
  entities: Record<string, Ticket>;
};

const ticketSchema = new schema.Entity("tickets", {}, { idAttribute: "id" });
const ticketListSchema = new schema.Array(ticketSchema);

export function getNormlaizedTickets(tickets: Ticket[]): NormalizedTickets {
  const normalizedTickets = normalize(tickets, ticketListSchema);

  return {
    ids: normalizedTickets.result,
    entities: normalizedTickets.entities as Record<string, Ticket>,
  };
}

export function upsertTickets(
  normalTickets: NormalizedTickets,
  tickets: Ticket[],
  addToIds?: boolean,
) {
  for (const ticket of tickets) {
    if (addToIds && !normalTickets.ids.includes(ticket.id)) {
      normalTickets.ids.push(ticket.id);
    }

    normalTickets.entities[ticket.id] = ticket;
  }
}
