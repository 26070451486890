import SendRoundedIcon from "@mui/icons-material/SendRounded";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
} from "@mui/material";
import { isBlank } from "@trainwell/features";
import { addDays, format, startOfDay } from "date-fns";
import { useEffect, useState } from "react";
import { DialogTitleWithClose } from "src/components/misc/DialogTitleWithClose";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { getSelectedDays } from "src/lib/habits";
import { sendTextMessage } from "src/slices/chatSlice";
import { selectAllHabitPlans } from "src/slices/clientSlice";

type Props = {
  userId: string;
  open: boolean;
  onClose: () => void;
};

export function FirstWeekDialog({ userId, open, onClose }: Props) {
  const dispatch = useAppDispatch();
  const client = useAppSelector((state) => state.client.client);
  const [sendingMessage, setSendingMessage] = useState(false);
  const defaultMessage = useAppSelector((state) => {
    const habitPlans = selectAllHabitPlans(state);

    if (client) {
      let date = startOfDay(new Date());
      for (let weekIndex = habitPlans.length - 1; weekIndex >= 0; weekIndex--) {
        for (let day = 0; day < 7; day++) {
          for (const habitWeek of habitPlans[weekIndex].habit_weeks) {
            const selectedDays = getSelectedDays(
              habitWeek.schedule,
              habitWeek.custom_days,
            );
            if (selectedDays[day]) {
              date = addDays(new Date(habitPlans[weekIndex].date), day);
              break;
            }
          }
        }
      }

      return `Hey ${
        client.first_name
      }, I just finished building your routine! It starts ${format(
        date,
        "MMMM do",
      )}, hope you're excited to take your first steps toward some healthy habits!`;
    }

    return null;
  });
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (client && !message && defaultMessage) {
      setMessage(defaultMessage);
    }
  }, [client?.user_id]);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      onClose={() => {
        if (sendingMessage) {
          return;
        }

        onClose();
      }}
    >
      <DialogTitleWithClose
        onClose={() => {
          if (sendingMessage) {
            return;
          }

          onClose();
        }}
      >
        Send a message
      </DialogTitleWithClose>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          Let your client know you&apos;re finsihed with their awesome new plan!
        </DialogContentText>
        <TextField
          multiline
          minRows={2}
          fullWidth
          label="Message"
          value={message}
          onChange={(event) => {
            setMessage(event.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            onClose();
          }}
          disabled={sendingMessage}
        >
          This client doesn&apos;t need a message
        </Button>
        <LoadingButton
          variant="contained"
          loading={sendingMessage}
          startIcon={<SendRoundedIcon />}
          onClick={async () => {
            if (!userId || !message) {
              return;
            }

            setSendingMessage(true);

            dispatch(
              sendTextMessage({
                userId: userId,
                text: message,
              }),
            )
              .unwrap()
              .then(() => {
                setSendingMessage(false);
                onClose();
              });
          }}
          disabled={isBlank(message)}
        >
          Send message
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
