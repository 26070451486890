import { Box, CardActionArea, Stack, Typography } from "@mui/material";
import type { HabitTaskMessage } from "@trainwell/types";
import { format } from "date-fns";
import { useState } from "react";
import HabitDot from "src/components/misc/HabitDot";
import HabitTaskPopover from "src/components/misc/HabitTaskPopover";
import { useAppSelector } from "src/hooks/stateHooks";

type Props = {
  message: HabitTaskMessage;
  combineBottom: boolean;
  disableInteractive?: boolean;
};

export default function HabitMessage({
  message,
  combineBottom,
  disableInteractive,
}: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isChatFullscreen = useAppSelector(
    (state) => state.chat.isChatFullscreen,
  );

  if (!message.habit_task) {
    return <Typography>Error loading habit message</Typography>;
  }

  return (
    <>
      <CardActionArea
        href={
          isChatFullscreen
            ? ""
            : message.habit_task.workout_log_id
              ? `/clients/${message.habit_task.user_id}/logs/${message.habit_task.workout_log_id}`
              : ""
        }
        onClick={
          disableInteractive
            ? undefined
            : (event) => {
                if (isChatFullscreen) {
                  setAnchorEl(event.currentTarget);
                }
              }
        }
        onContextMenu={
          !message.habit_task.workout_log_id
            ? undefined
            : (event) => {
                event.preventDefault();

                setAnchorEl(event.currentTarget);
              }
        }
        sx={{
          maxWidth: "300px",
          borderRadius: `12px 12px 12px ${!combineBottom ? "0px" : "12px"}`,
          overflow: "hidden",
          backgroundColor: (theme) => theme.palette.background.default,
          p: 1,
          display: "flex",
          alignItems: "center",
          border: 1,
          borderColor: "divider",
        }}
      >
        <Stack
          direction={"column"}
          spacing={1}
          useFlexGap
          sx={{
            whiteSpace: "pre-line",
            wordWrap: "break-word",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <HabitDot
              complete
              type={message.habit_task.type}
              sx={{ mr: 1 }}
              widthAndHeight={18}
            />
            <Box>
              <Typography
                variant="h6"
                sx={{
                  color: (theme) => theme.palette.text.primary,
                }}
              >
                {message.habit_task.name}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: (theme) => theme.palette.text.secondary,
                }}
              >
                {format(
                  new Date(message.habit_task.date_completed as string),
                  "MMM do, yyyy h:mm a",
                )}
              </Typography>
            </Box>
          </Box>
          {message.habit_task.notes_client && (
            <Typography
              sx={{
                color: (theme) => theme.palette.text.primary,
              }}
            >
              {message.habit_task.notes_client}
            </Typography>
          )}
          {message.habit_task.share_photo_with_coach &&
            message.habit_task.photo_url && (
              <img
                style={{ borderRadius: "8px", objectFit: "cover" }}
                width={100}
                height={133}
                alt="fitframe"
                src={message.habit_task.photo_url}
              />
            )}
        </Stack>
      </CardActionArea>
      <HabitTaskPopover
        habitTask={message.habit_task}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
      />
    </>
  );
}
