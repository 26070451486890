import { extractClosestEdge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { getReorderDestinationIndex } from "@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index";
import type {
  BaseEventPayload,
  ElementDragType,
} from "@atlaskit/pragmatic-drag-and-drop/types";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import type {
  Client,
  EquipmentObject,
  ExerciseSource,
  LogSet,
  SetHistory,
  Workout,
  WorkoutCycle,
  WorkoutExercise,
  WorkoutSection,
  WorkoutSet,
  WorkoutTemplate,
} from "@trainwell/types";
import { exerciseLibrary } from "@trainwell/workout-lib";
import { denormalize } from "normalizr";
import {
  exerciseGroups,
  exerciseMap,
  exercisesHaveSameValues,
  getExerciseById,
} from "src/lib/exercises";
import { api } from "src/lib/trainwellApi";
import { workoutLib } from "src/lib/trainwellWorkoutLib";
import type {
  NormalizedCycle,
  NormalizedSection,
  NormalizedWorkoutData,
} from "src/lib/workoutNormalization";
import {
  findFirstCycleSetIdFromSetId,
  findIdsFromSetId,
  findSectionIdForExercise,
  getExerciseIdsInSection,
  getFirstCycle,
  getFirstSectionExerciseIds,
  getFullCycle,
  getFullExercise,
  getFullSection,
  getNormalizedWorkout,
  handleDeleteExercise,
  handleDeleteSection,
  insertCycle,
  insertExercise,
  insertSections,
  insertSet,
  updateAllSetsInExercise,
  updateExercise,
  updateSet,
  workoutSchema,
} from "src/lib/workoutNormalization";
import type { RootState } from "src/slices/store";
import { logApi } from "./api/logApi";
import {
  createWorkoutTemplate,
  updateLocalPhaseTemplate,
} from "./phaseTemplatesSlice";
import { updateLocalWorkout, updateWorkout } from "./phasesSlice";
import {
  renameTemplate,
  saveNewTemplate,
  updateLocalTemplate,
} from "./templatesSlice";
import { selectPrimaryTrainer } from "./trainerSlice";

export const fetchWorkout = createAsyncThunk(
  "workout/fetchWorkout",
  async (
    data: { workoutId: string; fromLog: boolean },
    { getState, dispatch },
  ) => {
    const { workoutId, fromLog } = data;

    const state = getState() as RootState;

    const existingWorkoutId =
      state.workout.workoutNormalized?.result.workout_id;

    if (existingWorkoutId && existingWorkoutId !== workoutId) {
      await dispatch(saveWorkout(existingWorkoutId));
    }

    const workout = await api.workouts.getOne(workoutId);

    const normalizedWorkout = getNormalizedWorkout(workout);

    if (!workout.metadata.date_last_opened) {
      dispatch(
        updateWorkout({
          workout_id: workoutId,
          metadata: {
            ...workout.metadata,
            date_last_opened: new Date().toISOString(),
          },
        }),
      );
    }

    return {
      normalizedWorkout: normalizedWorkout,
    };
  },
);

export const shareWorkoutToCoach = createAsyncThunk(
  "workout/shareWorkoutToCoach",
  async (shareToTrainerId: string, { getState }) => {
    const state = getState() as RootState;

    const workout = selectDenormalizedWorkout(state);

    const newTemplate = workoutLib.templates.replaceTemplateUUIDs(
      workoutLib.templates.convertWorkoutToTemplate(
        workoutLib.workouts.prepareWorkoutToBeSaved(workout),
      ),
    );

    return api.workoutTemplates.shareToCoach(newTemplate, shareToTrainerId);
  },
);

export const saveAsTemplate = createAsyncThunk(
  "workout/saveAsTemplate",
  async (
    data: {
      name: string;
      tags: string[];
      onlySections?: boolean;
      clientMaxes?: Record<string, number>;
    },
    { getState, dispatch },
  ) => {
    const { name, tags, onlySections, clientMaxes } = data;

    const state = getState() as RootState;

    const workout = selectDenormalizedWorkout(state);
    const trainer = selectPrimaryTrainer(state);

    const workoutCopy = JSON.parse(JSON.stringify(workout)) as Workout;

    if (onlySections) {
      const selectedSectionIds = selectSelectedSections(state);

      workoutCopy.sections = workoutCopy.sections.filter((section) =>
        selectedSectionIds.includes(section.section_id),
      );
    }

    const newTemplate = workoutLib.templates.getNewTemplateFromWorkoutAndClient(
      workoutCopy!,
      trainer!.trainer_id,
      clientMaxes ?? {},
      exerciseMap!,
    );
    newTemplate.name = name;
    newTemplate.tags = tags;

    await dispatch(saveNewTemplate(newTemplate)).unwrap();

    await dispatch(
      createWorkoutTemplate({
        trainerId: trainer!.trainer_id,
        workoutTemplateId: newTemplate.template_id,
      }),
    );

    return;
  },
);

export const fetchTemplate = createAsyncThunk(
  "workout/fetchTemplate",
  async (templateID: string) => {
    const response = await api.workoutTemplates.getOne(templateID);

    return response.workout_template;
  },
);

export const saveWorkout = createAsyncThunk(
  "workout/saveWorkout",
  async (workoutId: string, { getState, dispatch }) => {
    console.log("Redux: Saving workout");

    const state = getState() as RootState;

    const workout = selectDenormalizedWorkout(state);

    if (workoutId !== workout.workout_id) {
      return;
    }

    const sanitizedWorkout =
      workoutLib.workouts.prepareWorkoutToBeSaved(workout);

    if (state.workout.isTemplate) {
      console.log("Redux: saving template");
      const newTemplate =
        workoutLib.templates.convertWorkoutToTemplate(sanitizedWorkout);

      dispatch(
        renameTemplate({
          templateID: newTemplate.template_id,
          newName: newTemplate.name,
        }),
      );

      dispatch(updateLocalTemplate(newTemplate));

      dispatch(
        updateLocalPhaseTemplate({
          workoutTemplateId: newTemplate.template_id,
          phaseTemplate: { name: newTemplate.name },
        }),
      );

      const response = await api.workoutTemplates.updateOne(newTemplate);

      return response.workout_template;
    } else {
      console.log("Redux: saving workout");

      sanitizedWorkout.sections.forEach((section) => {
        section.cycles.forEach((cycle) => {
          cycle.exercises.forEach((exercise) => {
            exercise.sets.forEach((set) => {
              delete set.intensity;
              delete set.reps_cache;
            });
          });
        });
      });

      const res = await api.workouts.updateOne(sanitizedWorkout);

      dispatch(updateLocalWorkout(sanitizedWorkout));

      return res;
    }
  },
);

export const handleDragEndInWorkoutBuilder = createAsyncThunk(
  "workout/handleDragEndInWorkoutBuilder",
  async (
    dropResult: BaseEventPayload<ElementDragType>,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;

    const trainer = selectPrimaryTrainer(state);

    const source = dropResult.source;
    const sourceType = source.data.type as string | undefined;

    const target = dropResult.location.current.dropTargets[0];

    if (!target) {
      return;
    }

    const targetType = target.data.type as string | undefined;

    if (sourceType === "section" && targetType === "section") {
      // Reordering existing section within workout
      console.log("Dnd: reorder section");

      const sourceSectionId = source.data.sectionId as string;
      const sourceIndex = source.data.index as number;

      const targetSectionId = target.data.sectionId as string;
      const targetIndex = target.data.index as number;

      const closestEdgeOfTarget = extractClosestEdge(target.data);

      const finishIndex = getReorderDestinationIndex({
        startIndex: sourceIndex,
        closestEdgeOfTarget,
        indexOfTarget: targetIndex,
        axis: "vertical",
      });

      dispatch(
        moveSection({
          sectionId: sourceSectionId,
          newIndex: finishIndex,
        }),
      );
    } else if (sourceType === "exercise" && targetType === "exercise") {
      // Reordering existing exercise within workout
      console.log("Dnd: reorder exercise");

      const sourceSectionId = source.data.sectionId as string;
      const sourceExerciseId = source.data.exerciseId as string;
      const sourceIndex = source.data.index as number;

      const targetSectionId = target.data.sectionId as string;
      const targetIndex = target.data.index as number;

      const closestEdgeOfTarget = extractClosestEdge(target.data);

      let finishIndex = 0;

      if (sourceSectionId === targetSectionId) {
        finishIndex = getReorderDestinationIndex({
          startIndex: sourceIndex,
          closestEdgeOfTarget,
          indexOfTarget: targetIndex,
          axis: "vertical",
        });
      } else {
        finishIndex =
          closestEdgeOfTarget === "bottom" ? targetIndex + 1 : targetIndex;
      }

      dispatch(
        moveExercise({
          exerciseId: sourceExerciseId,
          newIndex: finishIndex,
          previousSectionId: sourceSectionId,
          newSectionId: targetSectionId,
        }),
      );
    } else if (sourceType === "exercise" && targetType === "empty_section") {
      // Reordering existing exercise within workout to an empty section
      console.log("Dnd: Reorder exercise into empty section");

      const sourceSectionId = source.data.sectionId as string;
      const sourceExerciseId = source.data.exerciseId as string;

      const targetSectionId = target.data.sectionId as string;

      dispatch(
        moveExercise({
          exerciseId: sourceExerciseId,
          newIndex: 0,
          previousSectionId: sourceSectionId,
          newSectionId: targetSectionId,
        }),
      );
    } else if (sourceType === "exercise" && targetType === "section") {
      // Reorder exercise into a new in-between section
      console.log("Dnd: Reorder exercise into a new in-between section");

      const sourceSectionId = source.data.sectionId as string;
      const sourceExerciseId = source.data.exerciseId as string;

      const targetIndex = target.data.index as number;

      const closestEdgeOfTarget = extractClosestEdge(target.data);

      const finishSectionIndex =
        closestEdgeOfTarget === "bottom" ? targetIndex + 1 : targetIndex;

      const newSectionId = crypto.randomUUID();

      dispatch(
        addSection({
          index: finishSectionIndex,
          forceSectionId: newSectionId,
        }),
      );

      dispatch(
        moveExercise({
          exerciseId: sourceExerciseId,
          newIndex: 0,
          previousSectionId: sourceSectionId,
          newSectionId: newSectionId,
        }),
      );
    } else if (
      sourceType === "exercise_source" &&
      (targetType === "empty_section" ||
        targetType === "exercise" ||
        targetType === "section" ||
        targetType === "empty_workout")
    ) {
      // Add exercise from exercise list
      console.log("Dnd: add exercise from list");

      let exerciseMasterId = source.data.exerciseSourceId as string;

      const targetSectionId = target.data.sectionId as string;
      // Default to index zero for empty sections
      let finishIndex = 0;

      // If we're not adding to an empty section, calculate the index
      // (we're dropping this into a list of exercises)
      if (targetType === "exercise") {
        const targetIndex = target.data.index as number;

        const closestEdgeOfTarget = extractClosestEdge(target.data);

        finishIndex =
          closestEdgeOfTarget === "bottom" ? targetIndex + 1 : targetIndex;
      }

      if (!exerciseMap) {
        return;
      }

      if (state.client.client) {
        const exerciseSource = exerciseMap[exerciseMasterId];

        const group = exerciseGroups.find(
          (group) => group.picker_group_id === exerciseSource.picker_group_id,
        );

        const exerciseIds = group?.exercises.map((e) => e.id) as string[];

        const fullExercises = exerciseIds.map(
          (exerciseId) => exerciseMap![exerciseId],
        );

        const completedExerciseIds = exerciseIds
          .map((exerciseId) =>
            state.client.client?.latest_sets
              ? state.client.client.latest_sets[exerciseId]
              : undefined,
          )
          .map((s) => s?.exercise_master_id)
          .filter(Boolean);

        function rankNum(exerciseSource: ExerciseSource) {
          if (
            exerciseSource.timed_variant_id ||
            exerciseSource.reps_variant_id
          ) {
            if (
              completedExerciseIds.includes(exerciseSource.id) &&
              exerciseSource.reps_variant_id === null
            ) {
              return 3;
            } else if (completedExerciseIds.includes(exerciseSource.id)) {
              return 2;
            } else if (exerciseSource.reps_variant_id === null) {
              return 1;
            } else {
              return 0;
            }
          }

          if (
            exerciseSource.quick_variant_id ||
            exerciseSource.not_quick_variant_id
          ) {
            if (
              completedExerciseIds.includes(exerciseSource.id) &&
              exerciseSource.quick_variant_id === null
            ) {
              return 3;
            } else if (completedExerciseIds.includes(exerciseSource.id)) {
              return 2;
            } else if (exerciseSource.quick_variant_id === null) {
              return 1;
            } else {
              return 0;
            }
          }

          if (completedExerciseIds.includes(exerciseSource.id)) {
            return 1;
          } else {
            return 0;
          }
        }

        fullExercises.sort((a, b) => {
          const aRank = rankNum(a);
          const bRank = rankNum(b);

          const aLatestSet = state.client.client!.latest_sets
            ? state.client.client!.latest_sets[a.id]
            : undefined;
          const bLatestSet = state.client.client!.latest_sets
            ? state.client.client!.latest_sets[b.id]
            : undefined;

          if (aLatestSet && !bLatestSet) {
            return -1;
          } else if (!aLatestSet && bLatestSet) {
            return 1;
          } else if (aLatestSet && bLatestSet) {
            return (bLatestSet.set_end_date as string).localeCompare(
              aLatestSet.set_end_date as string,
            );
          }

          return aRank === bRank
            ? workoutLib.exercises.sortExercisesByEquipment(
                a,
                b,
                state.client.client!.equipment_detailed,
              )
            : bRank - aRank;
        });

        exerciseMasterId = fullExercises[0].id;
      }

      const bestSet = state.client.client?.best_sets
        ? state.client.client.best_sets[exerciseMasterId]
        : undefined;

      const orm = bestSet
        ? bestSet.estimated_orm
        : !trainer?.settings.disable_smart_orm && state.client.client
          ? state.client.client.maxes[exerciseMasterId]
          : undefined;

      if (targetType === "empty_workout") {
        const newSectionId = crypto.randomUUID();

        dispatch(
          addSection({
            index: 0,
            forceSectionId: newSectionId,
          }),
        );

        dispatch(
          addExercise({
            exerciseName: exerciseMasterId,
            equipment: state.client.client?.equipment_detailed,
            bestSet: bestSet ?? undefined,
            orm: orm,
            index: 0,
            sectionId: newSectionId,
          }),
        );
      } else if (targetType === "section") {
        console.log("Dnd: creating a new in-between section");

        const targetIndex = target.data.index as number;

        const closestEdgeOfTarget = extractClosestEdge(target.data);

        const finishSectionIndex =
          closestEdgeOfTarget === "bottom" ? targetIndex + 1 : targetIndex;

        const newSectionId = crypto.randomUUID();

        dispatch(
          addSection({
            index: finishSectionIndex,
            forceSectionId: newSectionId,
          }),
        );

        dispatch(
          addExercise({
            exerciseName: exerciseMasterId,
            equipment: state.client.client?.equipment_detailed,
            bestSet: bestSet ?? undefined,
            orm: orm,
            index: 0,
            sectionId: newSectionId,
          }),
        );
      } else {
        dispatch(
          addExercise({
            exerciseName: exerciseMasterId,
            equipment: state.client.client?.equipment_detailed,
            bestSet: bestSet ?? undefined,
            orm: orm,
            index: finishIndex,
            sectionId: targetSectionId,
          }),
        );
      }
    } else if (
      sourceType === "template" &&
      (targetType === "section" || targetType === "empty_workout")
    ) {
      // Drag template into workout
      console.log("Dnd: add template");

      const sourceTemplateId = source.data.templateId as string;

      const { workout_template: template } =
        await api.workoutTemplates.getOne(sourceTemplateId);

      const bestSets = state.client.client!.best_sets ?? {};

      if (targetType === "empty_workout") {
        dispatch(
          addTemplate({
            bestSets: bestSets,
            orms: !trainer?.settings.disable_smart_orm
              ? state.client.client!.maxes
              : undefined,
            equipment: state.client.client!.equipment_detailed,
            template: template,
            index: 0,
          }),
        );
      } else {
        const targetIndex = target.data.index as number;

        const closestEdgeOfTarget = extractClosestEdge(target.data);

        const finishIndex =
          closestEdgeOfTarget === "bottom" ? targetIndex + 1 : targetIndex;

        dispatch(
          addTemplate({
            bestSets: bestSets,
            orms: !trainer?.settings.disable_smart_orm
              ? state.client.client!.maxes
              : undefined,
            equipment: state.client.client!.equipment_detailed,
            template: template,
            index: finishIndex,
          }),
        );
      }
    }
  },
);

// -----------------
// Swapping
// -----------------

export const swapExercise = createAsyncThunk(
  "workout/swapExercise",
  async (
    data: {
      newExerciseMasterId: string;
      forceSelectedExerciseIds?: string[];
    },
    { getState },
  ) => {
    const { newExerciseMasterId, forceSelectedExerciseIds } = data;

    const state = getState() as RootState;

    const trainer = selectPrimaryTrainer(state);

    let bestSets: undefined | Record<string, SetHistory> = undefined;
    let equipment: undefined | EquipmentObject = undefined;
    let orms: undefined | Record<string, number> = undefined;
    let oldOrms: undefined | Record<string, number> = undefined;

    const exerciseIdsToSwap =
      forceSelectedExerciseIds &&
      !state.workout.selectedExercises.includes(forceSelectedExerciseIds[0])
        ? [...forceSelectedExerciseIds]
        : [...state.workout.selectedExercises];
    const newExerciseSource = exerciseMap![newExerciseMasterId];

    let exerciseMasterIdsToSwapTo: string[] = [];

    for (const oldExerciseId of exerciseIdsToSwap) {
      const originalExercise = JSON.parse(
        JSON.stringify(
          getFullExercise(state.workout.workoutNormalized!, oldExerciseId),
        ),
      ) as WorkoutExercise;

      let swapToExerciseMasterId = newExerciseMasterId;

      // Get the exercise we're swapping to
      // It should be as similar to the previous exercise as possible
      const exerciseWithMatchingEquipment =
        workoutLib.exercises.getSimilarExerciseInGroup({
          fromExerciseSourceId: originalExercise.exercise_master_id,
          targetPickerGroupId: newExerciseSource.picker_group_id,
          clientEquipment: state.workout.isTemplate
            ? undefined
            : state.client.client?.equipment_detailed,
        });

      if (exerciseWithMatchingEquipment) {
        swapToExerciseMasterId = exerciseWithMatchingEquipment.id;
      }

      exerciseMasterIdsToSwapTo.push(swapToExerciseMasterId);
    }

    exerciseMasterIdsToSwapTo = [...new Set(exerciseMasterIdsToSwapTo)];

    if (!state.workout.isTemplate) {
      bestSets = state.client.client!.best_sets ?? {};
      orms = {};
      oldOrms = {};

      equipment = state.client.client!.equipment_detailed;

      if (!trainer?.settings.disable_smart_orm) {
        exerciseMasterIdsToSwapTo.map((swapToxerciseId) => {
          orms![swapToxerciseId] = state.client.client!.maxes[swapToxerciseId];
        });
      }

      const exerciseIds =
        forceSelectedExerciseIds ?? state.workout.selectedExercises;
      oldOrms = {};

      for (const exerciseId of exerciseIds) {
        const originalExercise = JSON.parse(
          JSON.stringify(
            getFullExercise(state.workout.workoutNormalized!, exerciseId),
          ),
        ) as WorkoutExercise;

        const bestSet = state.client.client!.best_sets
          ? state.client.client!.best_sets[originalExercise.exercise_master_id]
          : undefined;

        if (bestSet) {
          oldOrms![originalExercise.exercise_master_id] =
            bestSet?.estimated_orm;
        }
      }
    }

    return {
      ...data,
      oldOrms,
      bestSets,
      equipment,
      orms,
      trainerId: trainer?.trainer_id,
    };
  },
);

const equipmentWords = [
  "dumbbell",
  "barbell",
  "kettlebell",
  "band",
  "weight",
  "machine",
  "bar",
  "handle",
  "implement",
  "plate",
];

export const toggleEquipment = createAsyncThunk(
  "workout/toggleEquipment",
  async (
    data: {
      exerciseId: string;
      equipmentPickerId: string;
    },
    { getState, rejectWithValue },
  ) => {
    const { exerciseId, equipmentPickerId } = data;

    const state = getState() as RootState;

    if (!state.workout.workoutNormalized) {
      throw new Error("No normalized workout");
    }

    const trainer = selectPrimaryTrainer(state);

    let bestSets: undefined | Record<string, SetHistory> = undefined;
    let equipment: undefined | EquipmentObject = undefined;
    let orms: undefined | Record<string, number> = undefined;
    let oldOrms: undefined | Record<string, number> = undefined;

    let oldExerciseIds = [exerciseId];

    if (state.workout.selectedExercises.includes(exerciseId)) {
      oldExerciseIds = [...state.workout.selectedExercises];
    }

    let exerciseMasterIdsToSwapTo: string[] = [];

    for (const oldExerciseId of oldExerciseIds) {
      const oldExercise =
        state.workout.workoutNormalized!.entities.exercises[oldExerciseId];
      const oldExerciseSource = getExerciseById(oldExercise.exercise_master_id);

      const exerciseWithMatchingEquipment =
        workoutLib.exercises.getSimilarExerciseInGroup({
          fromExerciseSourceId: oldExercise.exercise_master_id,
          targetPickerGroupId: oldExerciseSource!.picker_group_id,
          forceEquipmentPickerId: equipmentPickerId,
        });

      if (!exerciseWithMatchingEquipment) {
        continue;
      }

      if (
        !exercisesHaveSameValues(
          oldExerciseSource!,
          exerciseWithMatchingEquipment,
        )
      ) {
        exerciseMasterIdsToSwapTo.push(exerciseWithMatchingEquipment.id);
      }
    }

    exerciseMasterIdsToSwapTo = [...new Set(exerciseMasterIdsToSwapTo)];

    if (!state.workout.isTemplate) {
      bestSets = state.client.client!.best_sets ?? {};
      orms = {};
      oldOrms = {};

      equipment = state.client.client!.equipment_detailed;

      if (!trainer?.settings.disable_smart_orm) {
        exerciseMasterIdsToSwapTo.map((swapToxerciseId) => {
          orms![swapToxerciseId] = state.client.client!.maxes[swapToxerciseId];
        });
      }

      for (const exerciseId of oldExerciseIds) {
        const originalExercise = JSON.parse(
          JSON.stringify(
            getFullExercise(state.workout.workoutNormalized!, exerciseId),
          ),
        ) as WorkoutExercise;

        const bestSet = state.client.client!.best_sets
          ? state.client.client!.best_sets[originalExercise.exercise_master_id]
          : undefined;

        if (bestSet) {
          oldOrms![originalExercise.exercise_master_id] = bestSet.estimated_orm;
        }
      }
    }

    for (const exerciseId of oldExerciseIds) {
      const exercise = getFullExercise(
        state.workout.workoutNormalized,
        exerciseId,
      );

      const setNotes = exercise.sets
        .map((set) => set.notes_trainer?.toLowerCase())
        .filter(Boolean) as string[];

      if (
        setNotes.some((note) =>
          equipmentWords.some((word) => note.includes(word)),
        ) &&
        !state.workout.warnedAboutEquipment
      ) {
        return rejectWithValue(true);
      }
    }

    return {
      equipment,
      oldOrms,
      bestSets,
      orms,
    };
  },
);

export const addressSetFeedback = createAsyncThunk(
  "workout/addressSetFeedback",
  async (data: { logId: string; setId: string }, { dispatch, getState }) => {
    const { logId, setId } = data;

    const state = getState() as RootState;

    const trainer = selectPrimaryTrainer(state);

    const alertsReq = dispatch(
      logApi.endpoints.getWorkoutLogAlerts.initiate(logId),
    );
    alertsReq.unsubscribe();

    const updates = (await alertsReq).data?.workout_log_alerts;

    if (!updates) {
      return null;
    }

    const filteredUpdates = updates.filter(
      (u) => u.trigger.scope === "exercise",
    );

    const workout = selectDenormalizedWorkout(state);

    const { sectionId, exerciseIndex } =
      findIdsFromSetId(state.workout.workoutNormalized!, setId) ?? {};

    const setIds =
      workout.sections
        .find((section) => section.section_id === sectionId)
        ?.cycles.map((cycle) => {
          return cycle.exercises[exerciseIndex!].sets.map((set) => set.set_id);
        })
        .flat() ?? [];

    const relevantUpdates = filteredUpdates
      .filter((update) => {
        return update.trigger.triggered_sets.some((triggeredSet) =>
          setIds.includes(triggeredSet.set_id),
        );
      })
      .filter((update) => !update.date_addressed);

    for (const update of relevantUpdates) {
      dispatch(
        logApi.endpoints.updateWorkoutLogAlert.initiate({
          logId: logId,
          trainerId: trainer!.trainer_id,
          workoutLogAlertId: update._id,
          addressType: "manually_changed",
          manuallyChangedType: "set_changed",
        }),
      );
    }

    return;
  },
);

export const acceptClientSwap = createAsyncThunk(
  "workout/acceptClientSwap",
  async (data: { logId: string; setId: string }, { dispatch, getState }) => {
    const { logId, setId } = data;

    const state = getState() as RootState;

    const logReq = dispatch(logApi.endpoints.getLog.initiate(logId));
    logReq.unsubscribe();

    const log = (await logReq).data;

    if (!log) {
      return null;
    }

    return {
      log: log,
    };
  },
);

// Define a type for the slice state
interface WorkoutState {
  // workout: Workout | undefined;
  workoutNormalized: NormalizedWorkoutData | undefined;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | undefined;
  selectedExercises: string[];
  isSelectingTemplate: boolean;
  isTemplate: boolean;
  saveStatus: "idle" | "saving" | "succeeded" | "failed";
  saveError: string | undefined;
  warnedAboutEquipment: boolean;
  equipmentWarningOpen: null | {
    exerciseId: string;
    equipmentPickerId: string;
  };
  highlightedSet: { setId: string; firstSetId: string } | undefined;
  exercisesOpen: boolean;
}

// Define the initial state using that type
const initialState: WorkoutState = {
  // workout: undefined,
  workoutNormalized: undefined,
  status: "idle",
  error: undefined,
  selectedExercises: [],
  isSelectingTemplate: false,
  isTemplate: false,
  saveStatus: "idle",
  saveError: undefined,
  warnedAboutEquipment: false,
  equipmentWarningOpen: null,
  highlightedSet: undefined,
  exercisesOpen: true,
};

export const workoutSclice = createSlice({
  name: "workout",
  initialState,
  reducers: {
    resetWorkout: () => {
      console.log("Redux: reset workout");
      return { ...initialState };
    },
    toggleExercisesOpen: (state) => {
      state.exercisesOpen = !state.exercisesOpen;
    },
    setWorkout: (state, action: PayloadAction<Workout | undefined>) => {
      const workout = action.payload;

      if (workout) {
        const normalizedWorkout = getNormalizedWorkout(workout);

        state.workoutNormalized = normalizedWorkout;
      } else {
        state.workoutNormalized = undefined;
      }
    },
    renameWorkout: (state, action: PayloadAction<string>) => {
      if (!state.workoutNormalized) {
        return;
      }

      const workoutName = action.payload;

      state.workoutNormalized.result.name = workoutName;
    },
    renameSection: (
      state,
      action: PayloadAction<{ newName: string; sectionId: string }>,
    ) => {
      if (!state.workoutNormalized) {
        return;
      }

      const { newName, sectionId } = action.payload;

      state.workoutNormalized.entities.sections[sectionId].section_name =
        newName === "" ? null : newName;
    },
    deleteSection: (state, action: PayloadAction<string>) => {
      if (!state.workoutNormalized) {
        return;
      }

      const sectionId = action.payload;

      const exerciseIds = getExerciseIdsInSection(
        state.workoutNormalized,
        sectionId,
      );

      state.selectedExercises = state.selectedExercises.filter(
        (id) => !exerciseIds.includes(id),
      );

      handleDeleteSection(state.workoutNormalized, sectionId);
    },
    copySection: (state, action: PayloadAction<string>) => {
      const sectionId = action.payload;

      const currentIndex =
        state.workoutNormalized!.result.sections.indexOf(sectionId);

      const copy = JSON.parse(
        JSON.stringify(getFullSection(state.workoutNormalized!, sectionId)),
      ) as WorkoutSection;

      copy.section_id = crypto.randomUUID();

      copy.cycles.forEach((cycle) => {
        cycle.cycle_id = crypto.randomUUID();

        cycle.exercises.forEach((exercise) => {
          exercise.exercise_id = crypto.randomUUID();

          exercise.sets.forEach((set) => {
            set.set_id = crypto.randomUUID();
          });
        });
      });

      insertSections(state.workoutNormalized!, [copy], currentIndex + 1);
    },
    selectExercise: (state, action: PayloadAction<string>) => {
      const exerciseID = action.payload;

      if (!state.selectedExercises.includes(exerciseID)) {
        state.selectedExercises.push(exerciseID);
      }

      state.warnedAboutEquipment = false;
    },
    deselectExercise: (state, action: PayloadAction<string>) => {
      const exerciseID = action.payload;

      const index = state.selectedExercises.indexOf(exerciseID);
      if (index !== -1) {
        state.selectedExercises.splice(index, 1);
      }

      state.warnedAboutEquipment = false;
    },
    selectAllExercisesInSection: (state, action: PayloadAction<string>) => {
      if (!state.workoutNormalized) {
        return;
      }

      const sectionId = action.payload;

      const firstCycle = getFirstCycle(state.workoutNormalized, sectionId);

      state.selectedExercises = [
        ...new Set([...firstCycle.exercises, ...state.selectedExercises]),
      ];

      state.warnedAboutEquipment = false;
    },
    deselectAllExercisesInSection: (state, action: PayloadAction<string>) => {
      if (!state.workoutNormalized) {
        return;
      }

      const sectionId = action.payload;

      const firstCycle = getFirstCycle(state.workoutNormalized, sectionId);

      state.selectedExercises = state.selectedExercises.filter(
        (exerciseId) => !firstCycle.exercises.includes(exerciseId),
      );

      state.warnedAboutEquipment = false;
    },
    selectAllExercises: (state) => {
      if (!state.workoutNormalized) {
        return;
      }

      state.selectedExercises = getFirstSectionExerciseIds(
        state.workoutNormalized,
      );

      state.warnedAboutEquipment = false;
    },
    deselectAllExercises: (state) => {
      state.selectedExercises = [];

      state.warnedAboutEquipment = false;
    },
    updateSectionRepeats: (
      state,
      action: PayloadAction<{
        sectionId: string;
        repeats: number;
      }>,
    ) => {
      if (!state.workoutNormalized) {
        return;
      }

      const { sectionId, repeats } = action.payload;

      const selectedSectionIds = selectSelectedSections({
        workout: state,
      } as any);

      let sectionIds = [sectionId];

      if (selectedSectionIds.includes(sectionId)) {
        sectionIds = [...selectedSectionIds];
      }

      for (const sectionId of sectionIds) {
        const currentRepeats =
          state.workoutNormalized.entities.sections[sectionId].cycles.length;

        if (currentRepeats === repeats) {
          return;
        } else if (currentRepeats >= repeats) {
          state.workoutNormalized.entities.sections[sectionId].cycles.length =
            repeats;
        } else {
          while (
            state.workoutNormalized.entities.sections[sectionId].cycles.length <
            repeats
          ) {
            const firstCycle = getFirstCycle(
              state.workoutNormalized,
              sectionId,
            );
            const newCycle = JSON.parse(
              JSON.stringify(
                getFullCycle(state.workoutNormalized, firstCycle.cycle_id),
              ),
            ) as WorkoutCycle;

            // Generate new ids
            newCycle.cycle_id = crypto.randomUUID();
            newCycle.exercises.forEach((exercise) => {
              exercise.exercise_id = crypto.randomUUID();

              exercise.sets.forEach((set) => {
                set.set_id = crypto.randomUUID();
              });
            });

            insertCycle(
              state.workoutNormalized,
              newCycle,
              sectionId,
              state.workoutNormalized.entities.sections[sectionId].cycles
                .length,
            );
          }
        }
      }
    },
    addSection: (
      state,
      action: PayloadAction<{
        index?: number;
        forceSectionId?: string;
      }>,
    ) => {
      const { forceSectionId, index } = action.payload;

      if (!state.workoutNormalized) {
        return;
      }

      const newCycle: NormalizedCycle = {
        cycle_id: crypto.randomUUID(),
        exercises: [],
      };

      const newSection: NormalizedSection = {
        section_id: forceSectionId ?? crypto.randomUUID(),
        section_name: null,
        cycles: [newCycle.cycle_id],
      };

      if (index !== undefined) {
        state.workoutNormalized.result.sections.splice(
          index,
          0,
          newSection.section_id,
        );
      } else {
        state.workoutNormalized.result.sections.push(newSection.section_id);
      }

      state.workoutNormalized.entities.sections[newSection.section_id] =
        newSection;
      state.workoutNormalized.entities.cycles[newCycle.cycle_id] = newCycle;
    },
    addExercise: (
      state,
      action: PayloadAction<{
        equipment?: EquipmentObject;
        bestSet?: SetHistory;
        orm?: number;
        exerciseName: string;
        sectionId: string;
        index: number;
      }>,
    ) => {
      if (!state.workoutNormalized) {
        return;
      }

      const { exerciseName, equipment, bestSet, orm, sectionId, index } =
        action.payload;

      let newExercise: WorkoutExercise;

      if (state.isTemplate) {
        newExercise =
          workoutLib.templates.getDefaultTemplateExercise(exerciseName);
      } else {
        newExercise = workoutLib.exercises.getDefaultExercise({
          exerciseMasterId: exerciseName,
          bestSet: bestSet,
          orm: orm,
          equipment: equipment,
        });
      }
      newExercise.expanded = true;

      insertExercise(state.workoutNormalized, newExercise, sectionId, index);
    },
    toggleTimedAndReps: (
      state,
      action: PayloadAction<{
        exerciseId: string;
        client?: Client;
      }>,
    ) => {
      if (!state.workoutNormalized) {
        return;
      }

      const { exerciseId, client } = action.payload;

      const exercise = state.workoutNormalized.entities.exercises[exerciseId];

      const exerciseSource = getExerciseById(exercise.exercise_master_id);

      let switchTo: "timed" | "reps" = "timed";

      if (exerciseSource?.reps_variant_id) {
        switchTo = "reps";
      }

      let exerciseIds = [exerciseId];

      let wasSelected = false;
      if (state.selectedExercises.includes(exerciseId)) {
        wasSelected = true;
        exerciseIds = [...state.selectedExercises];
      }

      const newSelectedExerciseIds: string[] = [];

      for (const oldExerciseId of exerciseIds) {
        const oldExercise =
          state.workoutNormalized.entities.exercises[oldExerciseId];
        const oldExerciseSource = getExerciseById(
          oldExercise.exercise_master_id,
        );

        if (
          (switchTo === "reps" && !oldExerciseSource?.reps_variant_id) ||
          (switchTo === "timed" && !oldExerciseSource?.timed_variant_id)
        ) {
          continue;
        }

        const switchToExerciseMasterId =
          switchTo === "reps"
            ? oldExerciseSource?.reps_variant_id
            : oldExerciseSource?.timed_variant_id;

        if (!switchToExerciseMasterId) {
          continue;
        }

        const equipment = client?.equipment_detailed ?? {};

        const originalExercise = JSON.parse(
          JSON.stringify(
            getFullExercise(state.workoutNormalized, oldExerciseId),
          ),
        ) as WorkoutExercise;

        const newExercise = workoutLib.exercises.getSwappedExercise({
          oldExercise: originalExercise,
          newExerciseSourceId: switchToExerciseMasterId,
          equipment: equipment,
        });

        newExercise.expanded = true;

        const sectionId = findSectionIdForExercise(
          state.workoutNormalized,
          oldExerciseId,
        )!;
        const firstCycle = getFirstCycle(state.workoutNormalized, sectionId);
        const index =
          state.workoutNormalized.entities.cycles[
            firstCycle.cycle_id
          ].exercises.indexOf(oldExerciseId);

        const insertedExercise = insertExercise(
          state.workoutNormalized,
          newExercise,
          sectionId,
          index + 1,
        );

        newSelectedExerciseIds.push(insertedExercise!.exercise_id);

        handleDeleteExercise(state.workoutNormalized, oldExerciseId);

        if (state.selectedExercises.includes(oldExerciseId)) {
          state.selectedExercises = state.selectedExercises.filter(
            (id) => id !== oldExerciseId,
          );
        }
      }

      if (wasSelected) {
        state.selectedExercises = [
          ...new Set([...state.selectedExercises, ...newSelectedExerciseIds]),
        ];
      }
    },
    toggleManualAndAuto: (
      state,
      action: PayloadAction<{
        exerciseId: string;
        client?: Client;
      }>,
    ) => {
      if (!state.workoutNormalized) {
        return;
      }

      const { exerciseId, client } = action.payload;

      const exercise = state.workoutNormalized.entities.exercises[exerciseId];

      const exerciseSource = getExerciseById(exercise.exercise_master_id);

      let switchTo: "quick" | "not_quick" = "quick";

      if (exerciseSource?.not_quick_variant_id) {
        switchTo = "not_quick";
      }

      let exerciseIds = [exerciseId];

      let wasSelected = false;
      if (state.selectedExercises.includes(exerciseId)) {
        wasSelected = true;
        exerciseIds = [...state.selectedExercises];
      }

      const newSelectedExerciseIds: string[] = [];

      for (const oldExerciseId of exerciseIds) {
        const oldExercise =
          state.workoutNormalized.entities.exercises[oldExerciseId];
        const oldExerciseSource = getExerciseById(
          oldExercise.exercise_master_id,
        );

        if (
          (switchTo === "quick" && !oldExerciseSource?.quick_variant_id) ||
          (switchTo === "not_quick" && !oldExerciseSource?.not_quick_variant_id)
        ) {
          continue;
        }

        const switchToExerciseMasterId =
          switchTo === "quick"
            ? oldExerciseSource?.quick_variant_id
            : oldExerciseSource?.not_quick_variant_id;

        if (!switchToExerciseMasterId) {
          continue;
        }

        const equipment = client?.equipment_detailed ?? {};

        const originalExercise = JSON.parse(
          JSON.stringify(
            getFullExercise(state.workoutNormalized, oldExerciseId),
          ),
        ) as WorkoutExercise;

        const newExercise = workoutLib.exercises.getSwappedExercise({
          oldExercise: originalExercise,
          newExerciseSourceId: switchToExerciseMasterId,
          equipment: equipment,
        });

        newExercise.expanded = true;

        const sectionId = findSectionIdForExercise(
          state.workoutNormalized,
          oldExerciseId,
        )!;
        const firstCycle = getFirstCycle(state.workoutNormalized, sectionId);
        const index =
          state.workoutNormalized.entities.cycles[
            firstCycle.cycle_id
          ].exercises.indexOf(oldExerciseId);

        const insertedExercise = insertExercise(
          state.workoutNormalized,
          newExercise,
          sectionId,
          index + 1,
        );

        newSelectedExerciseIds.push(insertedExercise!.exercise_id);

        handleDeleteExercise(state.workoutNormalized, oldExerciseId);

        if (state.selectedExercises.includes(oldExerciseId)) {
          state.selectedExercises = state.selectedExercises.filter(
            (id) => id !== oldExerciseId,
          );
        }
      }

      if (wasSelected) {
        state.selectedExercises = [
          ...new Set([...state.selectedExercises, ...newSelectedExerciseIds]),
        ];
      }
    },
    deleteExercise: (state, action: PayloadAction<string>) => {
      if (!state.workoutNormalized) {
        return;
      }

      const exerciseId = action.payload;

      let exerciseIds = [exerciseId];

      if (state.selectedExercises.includes(exerciseId)) {
        exerciseIds = [...state.selectedExercises];

        state.selectedExercises = [];
      } else {
        state.selectedExercises = state.selectedExercises.filter(
          (selectedExerciseId) => exerciseId !== selectedExerciseId,
        );
      }

      for (const exerciseId of exerciseIds) {
        handleDeleteExercise(state.workoutNormalized, exerciseId);
      }
    },
    copyExercise: (state, action: PayloadAction<string>) => {
      if (!state.workoutNormalized) {
        return;
      }

      const exerciseId = action.payload;

      let exerciseIds = [exerciseId];

      let addToSelected = false;

      if (state.selectedExercises.includes(exerciseId)) {
        exerciseIds = [...state.selectedExercises];

        addToSelected = true;
      }

      for (const exerciseId of exerciseIds) {
        const exercise = getFullExercise(state.workoutNormalized, exerciseId);

        const sectionId = findSectionIdForExercise(
          state.workoutNormalized,
          exerciseId,
        )!;

        const currentIndex = getFirstCycle(
          state.workoutNormalized,
          sectionId,
        ).exercises.indexOf(exerciseId);

        const firstExercise = insertExercise(
          state.workoutNormalized,
          exercise,
          sectionId,
          currentIndex + 1,
        )!;

        if (addToSelected) {
          state.selectedExercises.push(firstExercise.exercise_id);
        }
      }

      state.selectedExercises = [...new Set(state.selectedExercises)];
    },
    addTemplate: (
      state,
      action: PayloadAction<{
        bestSets: Record<
          string,
          Pick<
            SetHistory,
            | "estimated_orm"
            | "reps_performed"
            | "weight_performed"
            | "time_performed"
          >
        >;
        orms?: Record<string, number>;
        equipment?: EquipmentObject;
        template: WorkoutTemplate;
        index: number;
      }>,
    ) => {
      const { template, bestSets, equipment, index, orms } = action.payload;

      const newWorkout = workoutLib.workouts.getWorkoutFromTemplateAndClient({
        template: template,
        bestSets: bestSets,
        orms: orms,
        equipment: equipment,
      });

      insertSections(state.workoutNormalized!, newWorkout.sections, index);
    },
    duplicateSets: (
      state,
      action: PayloadAction<{
        setIds: string[];
      }>,
    ) => {
      if (!state.workoutNormalized) {
        return;
      }

      const { setIds } = action.payload;

      const firstCycleSetIds = [
        ...new Set(
          setIds.map((setId) =>
            findFirstCycleSetIdFromSetId(state.workoutNormalized!, setId),
          ),
        ),
      ].filter(Boolean) as string[];

      for (const setId of firstCycleSetIds) {
        const { exerciseId, sectionId, setIndex } =
          findIdsFromSetId(state.workoutNormalized, setId) ?? {};

        if (!exerciseId || !sectionId || setIndex === undefined) {
          continue;
        }

        const set = state.workoutNormalized.entities.sets[setId];

        insertSet(
          state.workoutNormalized,
          set,
          sectionId,
          exerciseId,
          setIndex + 1,
        );
      }
    },
    deleteSets: (
      state,
      action: PayloadAction<{
        setIds: string[];
      }>,
    ) => {
      if (!state.workoutNormalized) {
        return;
      }

      const { setIds } = action.payload;

      const firstCycleSetIds = [
        ...new Set(
          setIds.map((setId) =>
            findFirstCycleSetIdFromSetId(state.workoutNormalized!, setId),
          ),
        ),
      ].filter(Boolean) as string[];

      for (const setId of firstCycleSetIds) {
        const { exerciseId, sectionId } =
          findIdsFromSetId(state.workoutNormalized, setId) ?? {};

        if (!exerciseId || !sectionId) {
          continue;
        }

        const exercise = state.workoutNormalized.entities.exercises[exerciseId];

        // Don't allow deleting the last set
        if (exercise.sets.length <= 1) {
          continue;
        }

        delete state.workoutNormalized.entities.sets[setId];

        const index = exercise.sets.indexOf(setId);

        updateExercise(
          state.workoutNormalized,
          exerciseId,
          sectionId,
          (exerciseId) => {
            state.workoutNormalized!.entities.exercises[exerciseId].sets.splice(
              index,
              1,
            );
          },
        );
      }
    },
    // acceptClientSwap: (
    //   state,
    //   action: PayloadAction<{
    //     setId: string
    //   }>,
    // ) => {
    //   if (!state.workoutNormalized) {
    //     return;
    //   }

    //   const { setId } = action.payload;

    //   const firstCycleSetIds = findFirstCycleSetIdFromSetId(state.workoutNormalized!, setId)

    //   if (!firstCycleSetIds) {
    //     return
    //   }

    //   const { exerciseId, sectionId } =
    //       findIdsFromSetId(state.workoutNormalized, setId) ?? {};

    //     if (!exerciseId || !sectionId) {
    //       return;
    //     }

    //     const exercise = getFullExercise(state.workoutNormalized, exerciseId);

    //     if (!exercise.)

    //     insertExercise(
    //       state.workoutNormalized,
    //       exercise,
    //       sectionId,
    //       index + 1,
    //     );

    //     handleDeleteExercise(state.workoutNormalized, exerciseId);

    //     updateSet(
    //       state.workoutNormalized,
    //       exerciseId,
    //       sectionId,
    //       setId,
    //       (setId) => {
    //         if (weight !== null) {
    //           state.workoutNormalized!.entities.sets[setId].weight_target =
    //             weight;
    //         }

    //         if (time !== null) {
    //           state.workoutNormalized!.entities.sets[setId].time_target = time;
    //         }

    //         if (reps !== null) {
    //           state.workoutNormalized!.entities.sets[setId].reps_target = reps;
    //         }

    //         if (rest !== null) {
    //           state.workoutNormalized!.entities.sets[setId].rest_target = rest;
    //         }
    //       },
    //     );
    // },
    updateSets: (
      state,
      action: PayloadAction<{
        setIds: string[];
        weight: number | null;
        reps: number | null;
        rest: number | null;
        time: number | null;
      }>,
    ) => {
      if (!state.workoutNormalized) {
        return;
      }

      const { setIds, weight, reps, rest, time } = action.payload;

      const firstCycleSetIds = [
        ...new Set(
          setIds.map((setId) =>
            findFirstCycleSetIdFromSetId(state.workoutNormalized!, setId),
          ),
        ),
      ].filter(Boolean) as string[];

      for (const setId of firstCycleSetIds) {
        const { exerciseId, sectionId } =
          findIdsFromSetId(state.workoutNormalized, setId) ?? {};

        if (!exerciseId || !sectionId) {
          continue;
        }

        updateSet(
          state.workoutNormalized,
          exerciseId,
          sectionId,
          setId,
          (setId) => {
            if (weight !== null) {
              state.workoutNormalized!.entities.sets[setId].weight_target =
                weight;
            }

            if (time !== null) {
              state.workoutNormalized!.entities.sets[setId].time_target = time;
            }

            if (reps !== null) {
              state.workoutNormalized!.entities.sets[setId].reps_target = reps;
            }

            if (rest !== null) {
              state.workoutNormalized!.entities.sets[setId].rest_target = rest;
            }
          },
        );
      }
    },
    updateWeight: (
      state,
      action: PayloadAction<{
        weight: number | null;
        sectionId: string;
        exerciseId: string;
        setId: string;
      }>,
    ) => {
      if (!state.workoutNormalized) {
        return;
      }

      const { weight, sectionId, exerciseId, setId } = action.payload;

      if (state.selectedExercises.includes(exerciseId)) {
        for (const selectedExerciseId of state.selectedExercises) {
          const sectionId = findSectionIdForExercise(
            state.workoutNormalized,
            selectedExerciseId,
          )!;

          updateAllSetsInExercise(
            state.workoutNormalized,
            selectedExerciseId,
            sectionId,
            (setId) => {
              state.workoutNormalized!.entities.sets[setId].weight_target =
                weight;
              if (!state.isTemplate) {
                state.workoutNormalized!.entities.sets[setId].intensity = null;
              }
            },
          );
        }
      } else {
        updateSet(
          state.workoutNormalized,
          exerciseId,
          sectionId,
          setId,
          (setId) => {
            state.workoutNormalized!.entities.sets[setId].weight_target =
              weight;
            if (!state.isTemplate) {
              state.workoutNormalized!.entities.sets[setId].intensity = null;
            }
          },
        );
      }
    },
    updateReps: (
      state,
      action: PayloadAction<{
        reps: number | null;
        sectionId: string;
        exerciseId: string;
        setId: string;
      }>,
    ) => {
      if (!state.workoutNormalized) {
        return;
      }

      const { reps, sectionId, exerciseId, setId } = action.payload;

      if (state.selectedExercises.includes(exerciseId)) {
        for (const selectedExerciseId of state.selectedExercises) {
          const sectionId = findSectionIdForExercise(
            state.workoutNormalized,
            selectedExerciseId,
          )!;

          updateAllSetsInExercise(
            state.workoutNormalized,
            selectedExerciseId,
            sectionId,
            (setId) => {
              state.workoutNormalized!.entities.sets[setId].reps_target = reps;
              if (!state.isTemplate) {
                state.workoutNormalized!.entities.sets[setId].intensity = null;
              }
              state.workoutNormalized!.entities.sets[setId].reps_cache = null;
            },
          );
        }
      } else {
        updateSet(
          state.workoutNormalized,
          exerciseId,
          sectionId,
          setId,
          (setId) => {
            state.workoutNormalized!.entities.sets[setId].reps_target = reps;
            if (!state.isTemplate) {
              state.workoutNormalized!.entities.sets[setId].intensity = null;
            }
            state.workoutNormalized!.entities.sets[setId].reps_cache = null;
          },
        );
      }
    },
    updateIntensity: (
      state,
      action: PayloadAction<{
        intensity: number;
        sectionId: string;
        exerciseId: string;
        setId: string;
      }>,
    ) => {
      if (!state.workoutNormalized) {
        return;
      }
      const { intensity, sectionId, exerciseId, setId } = action.payload;
      const convertedIntensity = Math.max(0, Number(intensity)) / 100;

      if (state.selectedExercises.includes(exerciseId)) {
        for (const selectedExerciseId of state.selectedExercises) {
          const sectionId = findSectionIdForExercise(
            state.workoutNormalized,
            selectedExerciseId,
          )!;

          updateAllSetsInExercise(
            state.workoutNormalized,
            selectedExerciseId,
            sectionId,
            (setId) => {
              state.workoutNormalized!.entities.sets[setId].intensity =
                convertedIntensity;
            },
          );
        }
      } else {
        updateSet(
          state.workoutNormalized,
          exerciseId,
          sectionId,
          setId,
          (setId) => {
            state.workoutNormalized!.entities.sets[setId].intensity =
              convertedIntensity;
          },
        );
      }
    },
    updateSeconds: (
      state,
      action: PayloadAction<{
        seconds: number;
        sectionId: string;
        exerciseId: string;
        setId: string;
      }>,
    ) => {
      if (!state.workoutNormalized) {
        return;
      }

      const { seconds, sectionId, exerciseId, setId } = action.payload;
      const time =
        state.workoutNormalized.entities.sets[setId].time_target ?? 0;
      const oldMinutes = Math.floor(time / 60);

      if (state.selectedExercises.includes(exerciseId)) {
        for (const selectedExerciseId of state.selectedExercises) {
          const sectionId = findSectionIdForExercise(
            state.workoutNormalized,
            selectedExerciseId,
          )!;

          updateAllSetsInExercise(
            state.workoutNormalized,
            selectedExerciseId,
            sectionId,
            (setId) => {
              state.workoutNormalized!.entities.sets[setId].time_target =
                oldMinutes * 60 + seconds;
              if (!state.isTemplate) {
                state.workoutNormalized!.entities.sets[setId].intensity = null;
              }
              state.workoutNormalized!.entities.sets[setId].reps_cache = null;
            },
          );
        }
      } else {
        updateSet(
          state.workoutNormalized,
          exerciseId,
          sectionId,
          setId,
          (setId) => {
            state.workoutNormalized!.entities.sets[setId].time_target =
              oldMinutes * 60 + seconds;
            if (!state.isTemplate) {
              state.workoutNormalized!.entities.sets[setId].intensity = null;
            }
            state.workoutNormalized!.entities.sets[setId].reps_cache = null;
          },
        );
      }
    },
    updateMinutes: (
      state,
      action: PayloadAction<{
        minutes: number;
        sectionId: string;
        exerciseId: string;
        setId: string;
      }>,
    ) => {
      if (!state.workoutNormalized) {
        return;
      }

      const { minutes, sectionId, exerciseId, setId } = action.payload;
      const time =
        state.workoutNormalized.entities.sets[setId].time_target ?? 0;
      const oldMinutes = Math.floor(time / 60);
      const oldSeconds = time - oldMinutes * 60;

      if (state.selectedExercises.includes(exerciseId)) {
        for (const selectedExerciseId of state.selectedExercises) {
          const sectionId = findSectionIdForExercise(
            state.workoutNormalized,
            selectedExerciseId,
          )!;

          updateAllSetsInExercise(
            state.workoutNormalized,
            selectedExerciseId,
            sectionId,
            (setId) => {
              state.workoutNormalized!.entities.sets[setId].time_target =
                minutes * 60 + oldSeconds;
              if (!state.isTemplate) {
                state.workoutNormalized!.entities.sets[setId].intensity = null;
              }
              state.workoutNormalized!.entities.sets[setId].reps_cache = null;
            },
          );
        }
      } else {
        updateSet(
          state.workoutNormalized,
          exerciseId,
          sectionId,
          setId,
          (setId) => {
            state.workoutNormalized!.entities.sets[setId].time_target =
              minutes * 60 + oldSeconds;
            if (!state.isTemplate) {
              state.workoutNormalized!.entities.sets[setId].intensity = null;
            }
            state.workoutNormalized!.entities.sets[setId].reps_cache = null;
          },
        );
      }
    },
    updateTime: (
      state,
      action: PayloadAction<{
        seconds: number;
        sectionId: string;
        exerciseId: string;
        setId: string;
      }>,
    ) => {
      if (!state.workoutNormalized) {
        return;
      }

      const { seconds, sectionId, exerciseId, setId } = action.payload;

      if (state.selectedExercises.includes(exerciseId)) {
        for (const selectedExerciseId of state.selectedExercises) {
          const sectionId = findSectionIdForExercise(
            state.workoutNormalized,
            selectedExerciseId,
          )!;

          updateAllSetsInExercise(
            state.workoutNormalized,
            selectedExerciseId,
            sectionId,
            (setId) => {
              state.workoutNormalized!.entities.sets[setId].time_target =
                seconds;
              if (!state.isTemplate) {
                state.workoutNormalized!.entities.sets[setId].intensity = null;
              }
              state.workoutNormalized!.entities.sets[setId].reps_cache = null;
            },
          );
        }
      } else {
        updateSet(
          state.workoutNormalized,
          exerciseId,
          sectionId,
          setId,
          (setId) => {
            state.workoutNormalized!.entities.sets[setId].time_target = seconds;
            if (!state.isTemplate) {
              state.workoutNormalized!.entities.sets[setId].intensity = null;
            }
            state.workoutNormalized!.entities.sets[setId].reps_cache = null;
          },
        );
      }
    },
    updateRest: (
      state,
      action: PayloadAction<{
        rest: number;
        sectionId: string;
        exerciseId: string;
        setId: string;
      }>,
    ) => {
      if (!state.workoutNormalized) {
        return;
      }

      const { rest, sectionId, exerciseId, setId } = action.payload;

      if (state.selectedExercises.includes(exerciseId)) {
        for (const selectedExerciseId of state.selectedExercises) {
          const sectionId = findSectionIdForExercise(
            state.workoutNormalized,
            selectedExerciseId,
          )!;

          updateAllSetsInExercise(
            state.workoutNormalized,
            selectedExerciseId,
            sectionId,
            (setId) => {
              state.workoutNormalized!.entities.sets[setId].rest_target = rest;
              if (!state.isTemplate) {
                state.workoutNormalized!.entities.sets[setId].intensity = null;
              }
            },
          );
        }
      } else {
        updateSet(
          state.workoutNormalized,
          exerciseId,
          sectionId,
          setId,
          (setId) => {
            state.workoutNormalized!.entities.sets[setId].rest_target = rest;
            if (!state.isTemplate) {
              state.workoutNormalized!.entities.sets[setId].intensity = null;
            }
          },
        );
      }
    },
    updateSetNotes: (
      state,
      action: PayloadAction<{
        notes: string;
        sectionId: string;
        exerciseId: string;
        setId: string;
      }>,
    ) => {
      if (!state.workoutNormalized) {
        return;
      }

      const { notes, sectionId, exerciseId, setId } = action.payload;

      if (state.selectedExercises.includes(exerciseId)) {
        for (const selectedExerciseId of state.selectedExercises) {
          const sectionId = findSectionIdForExercise(
            state.workoutNormalized,
            selectedExerciseId,
          )!;

          updateAllSetsInExercise(
            state.workoutNormalized,
            selectedExerciseId,
            sectionId,
            (setId) => {
              state.workoutNormalized!.entities.sets[setId].notes_trainer =
                notes;
            },
          );
        }
      } else {
        updateSet(
          state.workoutNormalized,
          exerciseId,
          sectionId,
          setId,
          (setId) => {
            state.workoutNormalized!.entities.sets[setId].notes_trainer = notes;
          },
        );
      }
    },
    updateSectionNotes: (
      state,
      action: PayloadAction<{
        notes: string | null;
        sectionId: string;
      }>,
    ) => {
      if (!state.workoutNormalized) {
        return;
      }

      const { notes, sectionId } = action.payload;

      for (const sid in state.workoutNormalized.entities.sections) {
        if (
          state.workoutNormalized.entities.sections[sid].notes_auto_generated &&
          sid !== sectionId
        ) {
          state.workoutNormalized.entities.sections[sid].notes = null;
          state.workoutNormalized.entities.sections[sid].notes_auto_generated =
            false;
        }
      }

      if (
        state.workoutNormalized.entities.sections[sectionId].notes === notes
      ) {
        return;
      }

      state.workoutNormalized.entities.sections[sectionId].notes =
        notes === "" ? null : notes;
      state.workoutNormalized.entities.sections[
        sectionId
      ].notes_auto_generated = notes ? true : false;
    },
    highlightSet: (
      state,
      action: PayloadAction<{
        firstExerciseId: string;
        firstSetId: string;
        setId: string;
        attributeToFocus?: "reps" | "weight" | "time" | "rest";
      }>,
    ) => {
      const { firstExerciseId, firstSetId, setId, attributeToFocus } =
        action.payload;

      const exercise =
        state.workoutNormalized?.entities.exercises[firstExerciseId];

      if (!exercise) {
        state.highlightedSet = undefined;
        return;
      }

      const wasExpanded = exercise.expanded;

      exercise.expanded = true;
      state.highlightedSet = {
        firstSetId: firstSetId,
        setId: setId,
      };

      document
        .getElementById(firstExerciseId)
        ?.scrollIntoView({ behavior: "smooth", block: "center" });

      if (attributeToFocus) {
        const element = document.getElementById(
          `${firstSetId}-${attributeToFocus}`,
        );

        // Need to delay focusing if the exercise wasn't expanded otherwise when it expends it'll steal focus
        if (wasExpanded) {
          element?.focus();
        } else {
          setTimeout(() => {
            element?.focus();
          }, 100);
        }
      }
    },
    setExerciseExpanded: (
      state,
      action: PayloadAction<{ exerciseId: string; expanded: boolean }>,
    ) => {
      const { exerciseId, expanded } = action.payload;

      const exercise = state.workoutNormalized?.entities.exercises[exerciseId]!;

      if (state.selectedExercises.includes(exerciseId)) {
        for (const exerciseId of state.selectedExercises) {
          const exercise =
            state.workoutNormalized?.entities.exercises[exerciseId]!;

          exercise.expanded = expanded;
        }
      } else {
        exercise.expanded = expanded;
      }
    },
    selectEveryExerciseWithID: (state, action: PayloadAction<string>) => {
      if (!state.workoutNormalized) {
        return;
      }

      const exerciseId = action.payload;

      const exercise = state.workoutNormalized.entities.exercises[exerciseId];

      const isSelected = state.selectedExercises.includes(exerciseId);

      const exerciseIds = getFirstSectionExerciseIds(state.workoutNormalized);

      const exercises = exerciseIds.map(
        (id) => state.workoutNormalized?.entities.exercises[id],
      );

      for (const foundExercise of exercises) {
        if (foundExercise?.exercise_master_id === exercise.exercise_master_id) {
          if (isSelected) {
            const index = state.selectedExercises.indexOf(
              foundExercise.exercise_id,
            );
            if (index !== -1) {
              state.selectedExercises.splice(index, 1);
            }
          } else {
            state.selectedExercises.push(foundExercise.exercise_id);
          }
        }
      }
    },
    moveExercise: (
      state,
      action: PayloadAction<{
        previousSectionId: string;
        newSectionId: string;
        exerciseId: string;
        newIndex: number;
      }>,
    ) => {
      if (!state.workoutNormalized) {
        return;
      }

      const { previousSectionId, newSectionId, exerciseId, newIndex } =
        action.payload;

      const originalExercise = JSON.parse(
        JSON.stringify(getFullExercise(state.workoutNormalized, exerciseId)),
      ) as WorkoutExercise;

      handleDeleteExercise(state.workoutNormalized, exerciseId);

      insertExercise(
        state.workoutNormalized,
        originalExercise,
        newSectionId,
        newIndex,
        true,
      );
    },
    updateTemplateTagsLocal: (
      state,
      action: PayloadAction<{ templateId: string; tags: string[] }>,
    ) => {
      const { templateId, tags } = action.payload;

      if (state.workoutNormalized?.result.workout_id === templateId) {
        // @ts-expect-error
        state.workoutNormalized.result.tags = tags;
      }
    },
    moveSection: (
      state,
      action: PayloadAction<{
        sectionId: string;
        newIndex: number;
      }>,
    ) => {
      if (!state.workoutNormalized) {
        return;
      }

      const { sectionId, newIndex } = action.payload;

      console.log("Redux: move section");

      state.workoutNormalized.result.sections =
        state.workoutNormalized.result.sections.filter(
          (id) => id !== sectionId,
        );
      console.log(state.workoutNormalized.result.sections);
      state.workoutNormalized.result.sections.splice(newIndex, 0, sectionId);
      console.log(state.workoutNormalized.result.sections);
    },
    toggleIsInsertingTemplate: (state) => {
      state.isSelectingTemplate = !state.isSelectingTemplate;
    },
    setEquipmentWarningDialog: (
      state,
      action: PayloadAction<null | {
        exerciseId: string;
        equipmentPickerId: string;
      }>,
    ) => {
      state.equipmentWarningOpen = action.payload;
    },
    updateWorkoutNotes: (state, action: PayloadAction<string>) => {
      if (!state.workoutNormalized) {
        return;
      }

      const notes = action.payload;
      state.workoutNormalized.result.notes_trainer = notes;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWorkout.pending, (state, action) => {
      const { fromLog } = action.meta.arg;

      state.status = "loading";

      if (fromLog) {
        state.exercisesOpen = false;
      } else {
        state.exercisesOpen = true;
      }
    });
    builder.addCase(fetchWorkout.fulfilled, (state, action) => {
      state.status = "succeeded";
      const { normalizedWorkout } = action.payload;

      console.log(
        `Redux: Fetched workout '${normalizedWorkout.result.workout_id}'`,
      );

      normalizedWorkout.result.metadata.date_last_opened =
        new Date().toISOString();

      state.workoutNormalized = normalizedWorkout;
      state.selectedExercises = [];
      state.highlightedSet = undefined;
      state.warnedAboutEquipment = false;
    });
    builder.addCase(fetchWorkout.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
    builder.addCase(fetchTemplate.pending, (state) => {
      state.status = "loading";
      state.isTemplate = true;
    });
    builder.addCase(fetchTemplate.fulfilled, (state, action) => {
      state.status = "succeeded";
      const fetchedTemplate = action.payload;

      console.log(
        "Redux: Fetched template with id",
        fetchedTemplate.template_id,
      );

      // We now have to convert the template into a workout object so typescript doesn't yell at me
      const newWorkout =
        workoutLib.workouts.convertTemplateToWorkout(fetchedTemplate);

      state.isTemplate = true;

      const normalizedWorkout = getNormalizedWorkout(newWorkout);

      state.workoutNormalized = normalizedWorkout;
      state.exercisesOpen = true;
    });
    builder.addCase(fetchTemplate.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
    builder.addCase(saveWorkout.pending, (state) => {
      state.saveStatus = "saving";
    });
    builder.addCase(saveWorkout.fulfilled, (state) => {
      console.log("Redux: Saved workout");
      state.saveStatus = "succeeded";
    });
    builder.addCase(saveWorkout.rejected, (state, action) => {
      state.saveStatus = "failed";
      state.saveError = action.error.message;
    });
    builder.addCase(toggleEquipment.rejected, (state) => {
      state.warnedAboutEquipment = true;
    });
    builder.addCase(toggleEquipment.fulfilled, (state, action) => {
      if (!state.workoutNormalized) {
        return;
      }

      state.warnedAboutEquipment = false;
      state.equipmentWarningOpen = null;

      const { exerciseId, equipmentPickerId } = action.meta.arg;
      const { equipment, bestSets, orms, oldOrms } = action.payload;

      let exerciseIds = [exerciseId];

      let wasSelected = false;
      if (state.selectedExercises.includes(exerciseId)) {
        wasSelected = true;
        exerciseIds = [...state.selectedExercises];
      }

      const newSelectedExerciseIds: string[] = [];

      for (const oldExerciseId of exerciseIds) {
        const oldExercise =
          state.workoutNormalized.entities.exercises[oldExerciseId];
        const oldExerciseSource = getExerciseById(
          oldExercise.exercise_master_id,
        );

        const exerciseWithMatchingEquipment =
          workoutLib.exercises.getSimilarExerciseInGroup({
            fromExerciseSourceId: oldExercise.exercise_master_id,
            targetPickerGroupId: oldExerciseSource!.picker_group_id,
            forceEquipmentPickerId: equipmentPickerId,
          });

        if (!exerciseWithMatchingEquipment) {
          continue;
        }

        const switchToExerciseMasterId = exerciseWithMatchingEquipment.id;

        if (!switchToExerciseMasterId) {
          continue;
        }

        const originalExercise = JSON.parse(
          JSON.stringify(
            getFullExercise(state.workoutNormalized, oldExerciseId),
          ),
        ) as WorkoutExercise;

        const newExercise = workoutLib.exercises.getSwappedExercise({
          oldExercise: originalExercise,
          newExerciseSourceId: switchToExerciseMasterId,
          equipment: equipment,
          bestSet: bestSets?.[switchToExerciseMasterId],
          orm: orms?.[switchToExerciseMasterId],
          oldOrm: oldOrms?.[switchToExerciseMasterId],
        });

        newExercise.expanded = true;

        const sectionId = findSectionIdForExercise(
          state.workoutNormalized,
          oldExerciseId,
        )!;
        const firstCycle = getFirstCycle(state.workoutNormalized, sectionId);
        const index =
          state.workoutNormalized.entities.cycles[
            firstCycle.cycle_id
          ].exercises.indexOf(oldExerciseId);

        const insertedExercise = insertExercise(
          state.workoutNormalized,
          newExercise,
          sectionId,
          index + 1,
        );

        newSelectedExerciseIds.push(insertedExercise!.exercise_id);

        handleDeleteExercise(state.workoutNormalized, oldExerciseId);

        if (state.selectedExercises.includes(oldExerciseId)) {
          state.selectedExercises = state.selectedExercises.filter(
            (id) => id !== oldExerciseId,
          );
        }
      }

      if (wasSelected) {
        state.selectedExercises = [
          ...new Set([...state.selectedExercises, ...newSelectedExerciseIds]),
        ];
      }
    });
    builder.addCase(swapExercise.fulfilled, (state, action) => {
      if (!state.workoutNormalized || !exerciseMap) {
        return;
      }

      const {
        newExerciseMasterId,
        bestSets,
        orms,
        oldOrms,
        equipment,
        forceSelectedExerciseIds,
        trainerId,
      } = action.payload;

      if (
        state.selectedExercises.length === 0 &&
        (!forceSelectedExerciseIds || forceSelectedExerciseIds.length === 0)
      ) {
        return;
      }

      const exerciseIdsToSwap =
        forceSelectedExerciseIds &&
        !state.selectedExercises.includes(forceSelectedExerciseIds[0])
          ? [...forceSelectedExerciseIds]
          : [...state.selectedExercises];

      const newExerciseSource = exerciseMap[newExerciseMasterId];

      for (const exerciseId of exerciseIdsToSwap) {
        const originalExercise = JSON.parse(
          JSON.stringify(getFullExercise(state.workoutNormalized, exerciseId)),
        ) as WorkoutExercise;
        const originalExerciseSource =
          exerciseLibrary[originalExercise.exercise_master_id];

        let swapToExerciseMasterId = newExerciseMasterId;

        // Get the exercise we're swapping to
        // It should be as similar to the previous exercise as possible
        const exerciseWithMatchingEquipment =
          workoutLib.exercises.getSimilarExerciseInGroup({
            fromExerciseSourceId: originalExercise.exercise_master_id,
            targetPickerGroupId: newExerciseSource.picker_group_id,
            clientEquipment: equipment,
          });

        if (exerciseWithMatchingEquipment) {
          swapToExerciseMasterId = exerciseWithMatchingEquipment.id;
        }

        const newExercise = workoutLib.exercises.getSwappedExercise({
          oldExercise: originalExercise,
          newExerciseSourceId: swapToExerciseMasterId,
          bestSet: bestSets?.[swapToExerciseMasterId],
          orm: orms?.[swapToExerciseMasterId],
          oldOrm: oldOrms?.[originalExercise.exercise_master_id],
          equipment: equipment,
        });

        newExercise.expanded = true;

        const sectionId = findSectionIdForExercise(
          state.workoutNormalized,
          exerciseId,
        )!;
        const firstCycle = getFirstCycle(state.workoutNormalized, sectionId);
        const index =
          state.workoutNormalized.entities.cycles[
            firstCycle.cycle_id
          ].exercises.indexOf(exerciseId);

        insertExercise(
          state.workoutNormalized,
          newExercise,
          sectionId,
          index + 1,
        );

        handleDeleteExercise(state.workoutNormalized, exerciseId);

        const swapToExerciseSource = exerciseLibrary[swapToExerciseMasterId];

        if (
          exerciseIdsToSwap.length === 1 &&
          !state.isTemplate &&
          state.workoutNormalized.result.user_id &&
          trainerId
        ) {
          api.analytics.trackEvent({
            eventType: "exercise_swap",
            platform: "coach_dash",
            sessionId: "",
            trainerId: trainerId,
            eventContent: {
              exercise_id_from: originalExercise.exercise_master_id,
              exercise_id_to: swapToExerciseMasterId,
              workout_id: state.workoutNormalized.result.workout_id,
              user_id: state.workoutNormalized.result.user_id,
              recommended: originalExerciseSource.picker_swap_ids.includes(
                swapToExerciseSource.picker_group_id,
              ),
            },
          });
        }
      }

      state.selectedExercises = [];
    });
    builder.addCase(acceptClientSwap.fulfilled, (state, action) => {
      if (!state.workoutNormalized || !action.payload) {
        return;
      }

      const { setId } = action.meta.arg;
      const { log } = action.payload;

      const firstCycleSetId = findFirstCycleSetIdFromSetId(
        state.workoutNormalized!,
        setId,
      );

      if (!firstCycleSetId) {
        return;
      }

      const { exerciseId, exerciseIndex, sectionId } =
        findIdsFromSetId(state.workoutNormalized, firstCycleSetId) ?? {};

      const logExercise = log.sections
        .find((section) => section.section_id === sectionId)
        ?.cycles[0].exercises.find(
          (exercise) =>
            exercise.swap_performed?.exercise_og.exercise_id === exerciseId,
        );

      if (!logExercise) {
        return;
      }

      const exercise: WorkoutExercise = {
        exercise_id: logExercise.exercise_id,
        exercise_master_id: logExercise.exercise_master_id,
        sets: logExercise.sets.map((logSet) => {
          const set: WorkoutSet = {
            set_id: logSet.set_id,
            notes_trainer: logSet.notes_trainer,
            reps_target: logSet.reps_target,
            rest_target: logSet.rest_target,
            time_target: logSet.time_target,
            weight_target: logSet.weight_target,
          };

          return set;
        }),
      };

      if (!exerciseId || !sectionId) {
        return;
      }

      insertExercise(
        state.workoutNormalized,
        exercise,
        sectionId,
        (exerciseIndex ?? 0) + 1,
      );

      handleDeleteExercise(state.workoutNormalized, exerciseId);
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  resetWorkout,
  setWorkout,
  renameWorkout,
  renameSection,
  deleteSection,
  copySection,
  selectExercise,
  deselectExercise,
  selectAllExercises,
  deselectAllExercises,
  deleteExercise,
  copyExercise,
  updateSetNotes,
  updateSectionNotes,
  setExerciseExpanded,
  highlightSet,
  moveExercise,
  moveSection,
  toggleIsInsertingTemplate,
  updateWorkoutNotes,
  addSection,
  addExercise,
  selectEveryExerciseWithID,
  duplicateSets,
  deleteSets,
  updateWeight,
  updateReps,
  updateIntensity,
  updateSeconds,
  updateMinutes,
  updateTime,
  updateRest,
  addTemplate,
  updateSectionRepeats,
  selectAllExercisesInSection,
  deselectAllExercisesInSection,
  toggleTimedAndReps,
  toggleManualAndAuto,
  setEquipmentWarningDialog,
  updateTemplateTagsLocal,
  updateSets,
  toggleExercisesOpen,
} = workoutSclice.actions;

export default workoutSclice.reducer;

export const selectSelectedSections = createSelector(
  [
    (state: RootState) => state.workout.workoutNormalized,
    (state: RootState) => state.workout.selectedExercises,
  ],
  (workoutNormalized, selectedExerciseIds) => {
    const selectedSectionIds: string[] = [];

    if (!workoutNormalized) {
      return selectedSectionIds;
    }

    for (const sectionId of workoutNormalized.result.sections) {
      const firstCycle = getFirstCycle(workoutNormalized, sectionId);

      if (
        firstCycle.exercises.length > 0 &&
        firstCycle.exercises.every((exerciseId) =>
          selectedExerciseIds.includes(exerciseId),
        )
      ) {
        selectedSectionIds.push(sectionId);
      }
    }

    return selectedSectionIds;
  },
);

export const selectSectionById = (state: RootState, sectionId: string) =>
  state.workout.workoutNormalized?.entities.sections[sectionId];

export const selectCycleById = (state: RootState, cycleId: string) =>
  state.workout.workoutNormalized?.entities.cycles[cycleId];

export const selectExerciseById = (state: RootState, exerciseId: string) =>
  state.workout.workoutNormalized?.entities.exercises[exerciseId];

export const selectSetById = (state: RootState, setId: string) =>
  state.workout.workoutNormalized?.entities.sets[setId];

export const selectDenormalizedWorkout = createSelector(
  [
    (state: RootState) => state.workout.workoutNormalized?.result,
    (state: RootState) => state.workout.workoutNormalized?.entities,
  ],
  (result, entities) => denormalize(result, workoutSchema, entities) as Workout,
);

export const selectWorkoutValidity = (state: RootState) => {
  const workout = selectDenormalizedWorkout(state);
  const client = state.client.client;

  return workoutLib.workouts.isValidWorkout(
    workout,
    state.workout.isTemplate,
    client?.equipment_detailed,
    client?.blacklisted_exercises,
    client?.preferred_weight_system ?? "imperial",
  );
};

export const selectExerciseValidity = (
  state: RootState,
  exerciseId: string,
  client?: Client,
) => {
  const exercise = getFullExercise(
    state.workout.workoutNormalized!,
    exerciseId,
  );

  return workoutLib.exercises.isValidExercise(
    exercise,
    state.workout.isTemplate,
    client?.equipment_detailed,
    client?.blacklisted_exercises,
    client?.preferred_weight_system ?? "imperial",
  );
};

export const selectSectionValidity = (
  state: RootState,
  sectionId: string,
  client?: Client,
) => {
  const section = getFullSection(state.workout.workoutNormalized!, sectionId);

  return workoutLib.workouts.isValidSection(
    section,
    state.workout.isTemplate,
    client?.equipment_detailed,
    client?.blacklisted_exercises,
    client?.preferred_weight_system ?? "imperial",
  );
};

export const selectIsSectionSelected = (
  state: RootState,
  sectionId: string,
) => {
  const selectedSections = selectSelectedSections(state);

  return selectedSections.includes(sectionId);
};

export const selectExerciseHasNotes = (
  state: RootState,
  exerciseId: string,
) => {
  const fullExercise = getFullExercise(
    state.workout.workoutNormalized!,
    exerciseId,
  );

  return workoutLib.exercises.doesExerciseHaveNotes(fullExercise);
};

const emptyArray = [];

export const makeSelectLogSets = () => {
  const selectLogSets = createSelector(
    [
      (state: RootState, logId: string | undefined) =>
        logId ? logApi.endpoints.getLog.select(logId)(state).data : undefined,
      (state: RootState, logId: string | undefined, sectionId: string) =>
        sectionId,
      (
        state: RootState,
        logId: string | undefined,
        sectionId: string,
        exerciseId: string,
      ) => exerciseId,
      (
        state: RootState,
        logId: string | undefined,
        sectionId: string,
        exerciseId: string,
        setId: string,
      ) => setId,
    ],
    (log, sectionId, exerciseId, setId) => {
      if (!log) {
        return emptyArray;
      }

      const exerciseIndex = log.sections
        .find((section) => section.section_id === sectionId)
        ?.cycles[0].exercises.findIndex(
          (e) =>
            e.exercise_id === exerciseId ||
            e.swap_performed?.exercise_og.exercise_id === exerciseId,
        );

      if (exerciseIndex === undefined) {
        return emptyArray;
      }

      const setIndexInLog = log.sections
        .find((section) => section.section_id === sectionId)
        ?.cycles[0].exercises[
          exerciseIndex
        ]?.sets.findIndex((s) => s.set_id === setId);

      if (setIndexInLog === undefined) {
        return emptyArray;
      }

      const sets = (
        log.sections
          .find((section) => section.section_id === sectionId)
          ?.cycles.map((cycle) => {
            return cycle.exercises[exerciseIndex]?.sets[setIndexInLog];
          }) ?? emptyArray
      ).filter(Boolean) as LogSet[];

      return sets;
    },
  );

  return selectLogSets;
};

export const makeSelectSwappedToExerciseMasterId = () => {
  const selectLogSets = createSelector(
    [
      (state: RootState, logId: string | undefined) =>
        logId ? logApi.endpoints.getLog.select(logId)(state).data : undefined,
      (state: RootState, logId: string | undefined, sectionId: string) =>
        sectionId,
      (
        state: RootState,
        logId: string | undefined,
        sectionId: string,
        exerciseId: string,
      ) => exerciseId,
    ],
    (log, sectionId, exerciseId) => {
      if (!log) {
        return undefined;
      }

      const swappedToExerciseMasterId = log.sections
        .find((section) => section.section_id === sectionId)
        ?.cycles[0].exercises.find(
          (e) =>
            e.exercise_id === exerciseId ||
            e.swap_performed?.exercise_og.exercise_id === exerciseId,
        )?.swap_performed?.exercise_og.exercise_master_id;

      return swappedToExerciseMasterId;
    },
  );

  return selectLogSets;
};
