import type { PayloadAction } from "@reduxjs/toolkit";
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import type { WorkoutTemplate } from "@trainwell/types";
import { api } from "src/lib/trainwellApi";
import { updateLocalPhaseTemplate } from "./phaseTemplatesSlice";
import type { RootState } from "./store";
import { updateTemplateTagsLocal } from "./workoutSlice";

export const fetchTemplates = createAsyncThunk(
  "templates/fetchTemplates",
  async (trainerID: string) => {
    const [workoutTemplates, trainwellWorkoutTemplates] = await Promise.all([
      api.workoutTemplates.getAll({ trainerId: trainerID }),
      api.workoutTemplates.getAll({ trainerId: "copilot" }),
    ]);

    return {
      workoutTemplates: [
        ...workoutTemplates.workout_templates,
        ...trainwellWorkoutTemplates.workout_templates,
      ],
    };
  },
);

export const addBlankTemplate = createAsyncThunk(
  "templates/addBlankTemplate",
  async (trainerID: string) => {
    const newTemplate: WorkoutTemplate = {
      template_id: crypto.randomUUID(),
      trainer_id: trainerID,
      name: "New Template",
      sections: [],
      version: "1.0.0",
      tags: null,
      metadata: {
        dates_updated: [],
        date_created: new Date().toString(),
        date_deleted: null,
        parent_template_id: null,
        from_auto_conversion: false,
        iteration: 0,
        perfect_exercise_test: false,
      },
    };

    const { workout_template } =
      await api.workoutTemplates.createOne(newTemplate);

    return workout_template;
  },
);

export const duplicateTemplate = createAsyncThunk(
  "templates/duplicateTemplate",
  async (templateId: string) => {
    const duplicatedTemplate = await api.workoutTemplates.duplicate(templateId);

    return duplicatedTemplate;
  },
);

export const deleteTemplate = createAsyncThunk(
  "templates/deleteTemplate",
  async (templateId: string) => {
    const response = await api.workoutTemplates.deleteOne(templateId);

    return response;
  },
);

export const saveNewTemplate = createAsyncThunk(
  "templates/saveNewTemplate",
  async (template: WorkoutTemplate) => {
    const { workout_template } = await api.workoutTemplates.createOne(template);

    return workout_template;
  },
);

export const updateTemplate = createAsyncThunk(
  "templates/updateTemplate",
  async (
    template: Partial<WorkoutTemplate> & Pick<WorkoutTemplate, "template_id">,
    { dispatch },
  ) => {
    const { workout_template } = await api.workoutTemplates.updateOne(template);

    if (template.tags) {
      dispatch(
        updateTemplateTagsLocal({
          templateId: template.template_id!,
          tags: template.tags,
        }),
      );
    }

    if (template.name) {
      dispatch(
        updateLocalPhaseTemplate({
          workoutTemplateId: template.template_id!,
          phaseTemplate: { name: template.name },
        }),
      );
    }

    return workout_template;
  },
);

// Define a type for the slice state
interface TemplatesState {
  templates: Pick<
    WorkoutTemplate,
    | "template_id"
    | "name"
    | "metadata"
    | "tags"
    | "notes_trainer"
    | "phase_template_id"
    | "trainer_id"
  >[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | undefined;
}

// Define the initial state using that type
const initialState: TemplatesState = {
  templates: [],
  status: "idle",
  error: undefined,
};

export const templatesSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    resetTemplates: () => initialState,
    updateLocalTemplate: (state, action: PayloadAction<WorkoutTemplate>) => {
      const newTemplate = action.payload;

      const index = state.templates.findIndex(
        (w) => w.template_id === newTemplate.template_id,
      );

      if (index === -1) {
        state.templates.unshift(newTemplate);
      } else {
        state.templates[index] = newTemplate;
      }
    },
    renameTemplate: (
      state,
      action: PayloadAction<{ templateID: string; newName: string }>,
    ) => {
      const { templateID, newName } = action.payload;

      const index = state.templates.findIndex(
        (w) => w.template_id === templateID,
      );

      if (index === -1) {
        return;
      }

      state.templates[index].name = newName;
    },
    addTemplatesLocal: (state, action: PayloadAction<WorkoutTemplate[]>) => {
      const workoutTemplates = action.payload;

      for (const workoutTemplate of workoutTemplates) {
        state.templates = state.templates.filter(
          (t) => t.template_id !== workoutTemplate.template_id,
        );

        state.templates.push(workoutTemplate);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTemplates.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchTemplates.fulfilled, (state, action) => {
      console.log("Redux: Got templates");
      state.status = "succeeded";

      let { workoutTemplates } = action.payload;

      workoutTemplates = workoutTemplates.sort((a, b) => {
        const aDate =
          a.metadata.dates_updated[a.metadata.dates_updated.length - 1] ??
          a.metadata.date_created;
        const bDate =
          b.metadata.dates_updated[b.metadata.dates_updated.length - 1] ??
          b.metadata.date_created;

        return -(aDate as string).localeCompare(bDate as string);
      });

      state.templates = workoutTemplates;
    });
    builder.addCase(fetchTemplates.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
    builder.addCase(addBlankTemplate.fulfilled, (state, action) => {
      state.templates.unshift(action.payload);
    });
    builder.addCase(saveNewTemplate.fulfilled, (state, action) => {
      const template = action.meta.arg;

      state.templates.unshift(template);
    });
    builder.addCase(duplicateTemplate.fulfilled, (state, action) => {
      const { workout_template } = action.payload;
      const oldTemplateId = action.meta.arg;

      const oldTemplateIndex = state.templates.findIndex(
        (template) => template.template_id === oldTemplateId,
      );

      state.templates.splice(oldTemplateIndex + 1, 0, workout_template);
    });
    builder.addCase(deleteTemplate.fulfilled, (state, action) => {
      const templateID = action.meta.arg;

      const index = state.templates.findIndex(
        (template) => template.template_id === templateID,
      );

      if (index === -1) {
        return;
      }

      state.templates.splice(index, 1);
    });
    builder.addCase(updateTemplate.fulfilled, (state, action) => {
      const newTemplate = action.payload;

      const templateIndex = state.templates.findIndex(
        (template) => template.template_id === newTemplate.template_id,
      );

      if (templateIndex === -1) {
        return;
      }

      state.templates[templateIndex] = newTemplate;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  updateLocalTemplate,
  resetTemplates,
  renameTemplate,
  addTemplatesLocal,
} = templatesSlice.actions;

export default templatesSlice.reducer;

export const selectCoachTemplates = (state: RootState) =>
  state.templates.templates;

export const selectTemplateById = (state: RootState, templateID: string) =>
  state.templates.templates.find(
    (template) => template.template_id === templateID,
  );

export const selectPossibleTemplateTags = createSelector(
  [selectCoachTemplates, (state: RootState, trainerId: string) => trainerId],
  (templates, trainerId) => {
    let newPossibleTags: string[] = [];

    const filteredTemplates = templates.filter(
      (t) => t.trainer_id === trainerId,
    );

    for (const template of filteredTemplates) {
      if ("tags" in template) {
        newPossibleTags = newPossibleTags.concat(template.tags ?? []);
      }
    }

    newPossibleTags = [...new Set(newPossibleTags)].sort();

    return newPossibleTags;
  },
);

export const selectTemplateLibrary = createSelector(
  [(state: RootState) => state.templates.templates],
  (templates) => {
    return templates;
  },
);
