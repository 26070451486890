import { Link, Typography } from "@mui/material";
import { useLayoutEffect, useRef, useState } from "react";

const URL_REGEX =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

function turnLinksIntoHtml(text: string) {
  const parts: any[] = text.split(URL_REGEX); // re is a matching regular expression
  for (let i = 1; i < parts.length; i += 2) {
    parts[i] = (
      <Link
        key={i}
        href={parts[i].replace(/\.$/, "")}
        target="_blank"
        color={"inherit"}
        sx={{
          overflowWrap: "break-word",
          wordBreak: "break-all",
        }}
      >
        {parts[i]}
      </Link>
    );
  }
  return parts;
}

type Props = {
  text: string;
  isFromMe: boolean;
  combineBottom: boolean;
  isFromOtherCoach?: boolean;
  isSavedForLater?: boolean;
};

export default function TextMessage({
  text,
  isFromMe,
  combineBottom,
  isFromOtherCoach,
  isSavedForLater,
}: Props) {
  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  useLayoutEffect(() => {
    // @ts-expect-error
    setHeight(ref.current?.clientHeight);
  }, [text]);

  const isOneLine = height <= 35;

  return (
    <Typography
      ref={ref}
      sx={{
        backgroundColor: (theme) =>
          isFromOtherCoach
            ? theme.palette.purpleSurface.main
            : isFromMe
              ? isSavedForLater
                ? theme.palette.mode === "light"
                  ? "#1e3a8a"
                  : "#dbeafe"
                : theme.palette.primary.main
              : isSavedForLater
                ? theme.palette.mode === "light"
                  ? "#dbeafe"
                  : "#1e40af"
                : theme.palette.background.default,
        color: (theme) =>
          isFromOtherCoach
            ? theme.palette.text.primary
            : isFromMe
              ? theme.palette.primary.contrastText
              : theme.palette.text.primary,
        borderRadius: isFromMe
          ? `${isOneLine ? "50px" : "12px"} ${isOneLine ? "50px" : "12px"} ${
              !combineBottom ? "2px" : isOneLine ? "50px" : "12px"
            } ${isOneLine ? "50px" : "12px"}`
          : `${isOneLine ? "50px" : "12px"} ${isOneLine ? "50px" : "12px"} ${
              isOneLine ? "50px" : "12px"
            } ${!combineBottom ? "2px" : isOneLine ? "50px" : "12px"}`,
        border: (theme) =>
          isSavedForLater
            ? `1px solid ${isFromMe ? (theme.palette.mode === "light" ? "#172554" : "#bfdbfe") : theme.palette.mode === "light" ? "#bfdbfe" : "#1d4ed8"}`
            : undefined,
        maxWidth: "300px",
        px: 1.5,
        py: 0.75,
        whiteSpace: "pre-line",
        wordWrap: "break-word",
      }}
    >
      {turnLinksIntoHtml(text)}
    </Typography>
  );
}
